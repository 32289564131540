import { Theme } from "@mui/material";

export const css = {
  eventWidgetContainer: (theme: Theme) => ({
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    justifyContent: "center",
    padding: "2.5rem",
    flex: 1,
    height: "100%",
    background: `linear-gradient(180deg, ${theme.palette.background.paperDarkBlue} 0%, ${theme.palette.background.paperDarkGray} 100%)`,
    borderRight: (theme: Theme) =>
      `1px solid ${theme.palette.background.opacityWhite}`,
    [theme.breakpoints.up("xl")]: { padding: "1.5rem" },
  }),
  eventWrapper: (theme: Theme) => ({
    borderRadius: "0.75rem",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  }),
  headerWrapper: (theme: Theme) => ({
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
    padding: "2rem 2.5rem 2.5rem 2.5rem",
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up("xl")]: { padding: "1rem 1.5rem" },
  }),
  checkpointContainer: {
    borderTop: (theme: Theme) =>
      `1px solid ${theme.palette.background.opacityWhite}`,
  },
  headerEventContainer: {
    position: "absolute",
    right: "2rem",
    display: "flex",
    justifyContent: "space-between",
    gap: "4rem",
  },
  headerEvent: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  event: {
    display: "flex",
    gap: "1.5rem",

    "&.alignCenter": {
      alignItems: "center",
    },
    "&.smallGap": {
      gap: "0.5rem",
    },
  },
  topText: {
    fontSize: "1rem",
    lineHeight: "1.125rem",
    whiteSpace: "nowrap",

    "&.grayText": {
      color: (theme: Theme) => theme.palette.text.primary,
    },
    "&.whiteText": {
      color: (theme: Theme) => theme.palette.text.onAccent,
    },
    "&.greenText": {
      color: (theme: Theme) => theme.palette.success.main,
    },
    "&.redText": {
      color: (theme: Theme) => theme.palette.error.main,
    },
  },
  closeButton: {
    cursor: "pointer",
    fontSize: "1rem",
    color: (theme: Theme) => theme.palette.main.gray,
    paddingRight: "0.5rem",
    textTransform: "uppercase",
  },
  titleTop: {
    color: (theme: Theme) => theme.palette.text.primary,
    fontSize: "1.25rem",
  },
  titleBottom: (openTitle: boolean, isLong: boolean | undefined) => ({
    color: (theme: Theme) => theme.palette.text.onAccent,
    fontSize: "1.25rem",
    paddingRight: isLong ? "7rem" : "2rem",
    fontWeight: "bold",
    maxHeight: openTitle ? "auto" : "3.5rem",
    overflow: "hidden",
    position: "relative",
  }),
  expandIcon: {
    width: "0.8rem",
    height: "0.8rem",
    cursor: "pointer",
    marginLeft: "0.8rem",
  },
  expandText: {
    position: "absolute",
    bottom: 0,
    right: 0,
    color: (theme: Theme) => theme.palette.main.gray,
    cursor: "pointer",
    lineHeight: "1.8rem",
  },
  sideBarEvent: {
    flexDirection: "column",
    flex: "0 0 25%",
    borderRight: (theme: Theme) =>
      `1px solid ${theme.palette.background.opacityWhite}`,
    justifyContent: "space-between",
  },
  sideBarTop: {
    padding: "1.5rem",
    borderBottom: (theme: Theme) =>
      `1px solid ${theme.palette.background.opacityWhite}`,
  },
  borderContainer: {
    borderBottom: (theme: Theme) =>
      `1px solid ${theme.palette.background.opacityWhite}`,
    paddingBottom: "1rem",
    marginBottom: "1rem",
  },
  achievementLevel: {
    display: "flex",
    alignItems: "baseline",
  },
  achievementLevelText: {
    color: (theme: Theme) => theme.palette.text.onAccent,

    "&.big": {
      fontSize: "3rem",
      fontWeight: "bold",
      lineHeight: "3rem",
    },
    "&.small": {
      fontSize: "1.5rem",
      lineHeight: "1.5rem",
    },
    "&.percent": {
      color: (theme: Theme) => theme.palette.text.primary,
      fontSize: "1rem",
      lineHeight: "1rem",
    },
  },
  sideBarTopHeaderText: {
    fontSize: "1.5rem",
    color: (theme: Theme) => theme.palette.text.onAccent,
  },
  sideBarTopItemContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem",
  },
  flexRow: {
    display: "flex",
    color: (theme: Theme) => theme.palette.text.onAccent,
    fontWeight: "bold",
    alignItems: "baseline",
    fontSize: "1.2rem",
    lineHeight: "1.2rem",
  },
  sideBarGrayText: {
    color: (theme: Theme) => theme.palette.text.primary,
    fontSize: "1rem",
    lineHeight: "1.2rem",
  },
  sideBarValueText: {
    fontSize: "1.2rem",
    lineHeight: "1.2rem",
    fontWeight: "bold",

    "&.red": {
      color: (theme: Theme) => theme.palette.error.main,
    },
    "&.green": {
      color: (theme: Theme) => theme.palette.success.main,
    },
    "&.white": {
      color: (theme: Theme) => theme.palette.text.onAccent,
    },
  },
  sideBarButton: (active: boolean) => ({
    width: "100%",
    border: (theme: Theme) =>
      `1px solid ${theme.palette.background.opacityWhite}`,
    padding: "1rem 0 0.7rem 0",
    color: (theme: Theme) => theme.palette.text.onAccent,
    fontSize: "1rem",
    lineHeight: "1rem",
    fontWeight: "bold",
    background: (theme: Theme) =>
      active ? theme.palette.main.blue : "transparent",
  }),
  contentEvent: {
    display: "flex",
    flexWrap: "nowrap",
    background: (theme: Theme) =>
      `linear-gradient(180deg, ${theme.palette.background.paperDarkBlue} 0%, ${theme.palette.background.paperDarkGray} 100%)`,
  },
  tabs: {
    "& .MuiTabs-root": {
      width: "100%",
    },
    "& .MuiTab-root": (theme: Theme) => ({
      fontSize: "1.25rem",
      padding: "1rem",
      [theme.breakpoints.down(1300)]: {
        fontSize: "0.7rem",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "1rem",
      },
    }),
    "& .MuiTabs-flexContainer": { justifyContent: "space-around" },
  },
  tabsEvent: {
    flex: "1",
    fontSize: "3rem",
    color: "white",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  textDate: {
    fontSize: "1.25rem",
  },
  itemsCount: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    "& span": {
      color: (theme: Theme) => theme.palette.text.secondary,
      fontSize: "1rem",
    },
  },
  widgetTitle: {
    color: (theme: Theme) => theme.palette.text.primary,
    fontSize: "1.125rem",
  },
  widgetDateContainer: {
    display: "flex",
    gap: "2.5rem",
  },
  widgetDate: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  widgetTitleBottom: {
    color: (theme: Theme) => theme.palette.text.secondary,
    fontSize: "1.125rem",
  },
};

import { Box, styled } from "@mui/material";

export const RootContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  backgroundColor: theme.palette.background.default,
  position: "relative",

  [theme.breakpoints.up("xl")]: {
    // height: "19vw",
    overflow: "hidden",
  },
}));

export const Content = styled("div")(({ theme }) => ({
  flex: "1 1 auto",
  padding: "7rem 0 2rem",
  height: "100%",

  [theme.breakpoints.up("lg")]: {
    padding: "2rem 0 2rem",
  },
}));

export const Overlay = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
});

export const PageContent = styled("div")({
  flex: "1 1 auto",
});

import {
  Box,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  SmallArrowDownActive,
  SmallArrowDownInActive,
  SmallArrowUpActive,
  SmallArrowUpInActive,
} from "ASSETS/svg/arrow";
import { SmallCloseGray } from "ASSETS/svg/close";
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  SyntheticEvent,
  useState,
} from "react";
import {
  Column,
  HeaderGroup,
  Row,
  SortingRule,
  TableBodyPropGetter,
  TableBodyProps,
  TablePropGetter,
  TableProps,
} from "react-table";
import {
  IGovProgramDataItem,
  INationalGoalsDataItem,
  IVicePremiersDataItem,
} from "SRC/pages/Main/common/ComplexGrid/util/interfaces";
import { IObjectDataItem } from "SRC/pages/Program/common/EventCard/EventTabs/ObjectTab/util/interfaces";
import { ISubjectDataItem } from "SRC/pages/Program/common/EventCard/EventTabs/SubjectTab/util/interfaces";
import { IEventItem } from "SRC/pages/Program/common/EventsSection/util/interfaces";
import { ModalEvent } from "SRC/pages/Program/common/Modals/ModalEvent/ModalEvent";
import { IStructureDataItem } from "SRC/pages/Program/common/StructureCard/util/interfaces";
import {
  ICashDataItem,
  ICashPremiersDataItem,
  IGeneralInfoDataItem,
  IGeneralInfoPremiersDataItem,
  IIndicatorsEventsDataItem,
  IIndicatorsPremiersEventsDataItem,
} from "SRC/pages/Summary/common/SummaryComplexGrid/util/interfaces";
import { useProgramFilters } from "SRC/redux/slices/gosprogram/hooks/useProgramFilters";

import { css } from "./BaseTable.styled";

interface IProps {
  columns: Column[];
  data: (
    | IGovProgramDataItem
    | IVicePremiersDataItem
    | INationalGoalsDataItem
    | ISubjectDataItem
    | IObjectDataItem
    | IEventItem
    | IGeneralInfoDataItem
    | ICashDataItem
    | IIndicatorsEventsDataItem
    | IGeneralInfoPremiersDataItem
    | ICashPremiersDataItem
    | IIndicatorsPremiersEventsDataItem
    | IStructureDataItem
  )[];
  sortedColumns?: string[];
  getTableProps: (propGetter?: TablePropGetter<any>) => TableProps;
  getTableBodyProps: (propGetter?: TableBodyPropGetter<any>) => TableBodyProps;
  headerGroups: Array<HeaderGroup<any>>;
  footerGroups?: Array<HeaderGroup<any>>;
  rows: Array<Row<any>>;
  prepareRow: (row: Row<any>) => void;
  setSortBy?: (sortBy: Array<SortingRule<any>>) => void;
  isSmall?: boolean;
  loading?: boolean;
  withBigRows?: boolean;
  tableHeight?: string | number;
  onClick?(id: number | string | undefined): void;
  onDoubleClick?(id: number | string): void;
  selectedRow?: undefined | string | number;
  setSelectedRow?(id: number | string | undefined): void;
  wrapSort?: boolean;
  isEventModal?: boolean;
  scrollHide?: boolean;
  positionTable?: string;
  forwardRef?: MutableRefObject<HTMLElement | undefined>;
  onScroll?: (scroll: any) => void;
  singleTable?: boolean;
  scrollX?: boolean;
  setEventModalMode?: Dispatch<
    SetStateAction<"default" | "eventCard" | "eventsFilter">
  >;
  eventModalMode?: "default" | "eventCard" | "eventsFilter";
  openTableModal?: boolean;
  setOpenEventCard?: Dispatch<SetStateAction<boolean>>;
}

const BaseTable = ({
  data,
  getTableProps,
  getTableBodyProps,
  headerGroups,
  footerGroups,
  rows,
  prepareRow,
  setSortBy,
  sortedColumns,
  isSmall,
  withBigRows,
  tableHeight,
  onClick,
  onDoubleClick,
  selectedRow,
  setSelectedRow,
  isEventModal,
  wrapSort,
  scrollHide,
  forwardRef,
  onScroll,
  singleTable,
  scrollX,
  setEventModalMode,
  openTableModal,
  setOpenEventCard,
}: IProps): JSX.Element => {
  const handleMultiSortBy = (column: any, setSortBy: any) => {
    const desc =
      column.isSortedDesc === true ? undefined : column.isSortedDesc === false;
    setSortBy([{ id: desc != null ? column.id : desc, desc }]);
  };
  const { setSelectedEventId } = useProgramFilters();

  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const isExtraLarge = useMediaQuery(theme.breakpoints.up("xl"));

  const multilineHeader = headerGroups.length > 1;

  const getHeaders = () => {
    const firstHeader = headerGroups[0].headers;
    let secondHeader = headerGroups[1].headers;

    const sortedHeader = firstHeader.map((firstHeaderColumn: any) => {
      const findColumn = secondHeader.find(
        (secondHeaderColumn: any) =>
          firstHeaderColumn.placeholderOf &&
          firstHeaderColumn.placeholderOf === secondHeaderColumn
      );

      if (findColumn) {
        secondHeader = secondHeader.map((secondHeader: any) => {
          if (secondHeader !== findColumn) {
            return secondHeader;
          } else {
            return firstHeaderColumn;
          }
        });
        return findColumn;
      }

      return firstHeaderColumn;
    });

    return [
      { ...headerGroups[0], headers: sortedHeader },
      { ...headerGroups[1], headers: secondHeader },
    ];
  };

  const headersToRender = multilineHeader ? getHeaders() : headerGroups;

  const handleDoubleClick = (id: number | string) => () => {
    onDoubleClick?.(id);
    if (isEventModal && !openTableModal && !isExtraLarge) {
      setOpen?.(true);
      setSelectedEventId(id);
    }
    if (openTableModal) {
      setEventModalMode?.("eventCard");
      setSelectedEventId(id);
    }
    setOpenEventCard?.(true);
  };
  const handleClick = (id: number | string) => () => {
    setSelectedRow?.(id);
    onClick?.(id);
  };
  const handleResetSelection = (e: SyntheticEvent) => {
    e.stopPropagation();
    setSelectedRow?.("");
  };

  return (
    <Box sx={css.wrapper(tableHeight, scrollHide, scrollX, theme) as SxProps}>
      <ModalEvent
        open={open}
        setOpen={setOpen}
        openTableModal={openTableModal}
      />
      <Box sx={css.headerBack as SxProps} />
      <Box
        sx={css.container(multilineHeader, singleTable)}
        onScroll={onScroll}
        ref={forwardRef}
      >
        <table {...getTableProps()}>
          <thead>
            {headersToRender.map((headerGroup: any, i: number) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any, index: number) => {
                  return sortedColumns?.includes(column.Header) ? (
                    <th
                      style={css.tableHeadCell}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      width={column.width !== 150 ? column.width : null}
                      key={index}
                      onClick={() => handleMultiSortBy(column, setSortBy)}
                    >
                      <Box
                        sx={css.tableHeadInnerContainer(
                          column.render("Header").length,
                          withBigRows,
                          wrapSort
                        )}
                      >
                        {column.render("Header")}
                        <Box sx={css.tableHeadSortContainer}>
                          {column.isSorted && !column.isSortedDesc ? (
                            <SmallArrowUpActive />
                          ) : (
                            <SmallArrowUpInActive />
                          )}{" "}
                          {column.isSorted && column.isSortedDesc ? (
                            <SmallArrowDownActive />
                          ) : (
                            <SmallArrowDownInActive />
                          )}
                        </Box>
                      </Box>
                    </th>
                  ) : (
                    <th
                      style={css.tableHeadCell}
                      width={column.width !== 150 ? column.width : null}
                      key={index}
                      {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: any, i: number) => {
              prepareRow(row);
              return (
                <tr
                  style={css.tableRow(
                    theme,
                    isSmall,
                    withBigRows,
                    multilineHeader,
                    Boolean(onClick) ||
                      Boolean(onDoubleClick) ||
                      Boolean(setSelectedRow) ||
                      isEventModal,
                    row.original?.id === selectedRow,
                    !selectedRow
                  )}
                  key={i}
                  onClick={handleClick(row.original.id)}
                  onDoubleClick={handleDoubleClick(row.original.id)}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell: any, index: number) => {
                    return (
                      <td
                        style={css.tableData}
                        key={index}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                        {row.original.id &&
                          row.original.id === selectedRow &&
                          index === row.cells.length - 1 && (
                            <Box
                              sx={css.resetSelection}
                              onClick={handleResetSelection}
                            >
                              <SmallCloseGray style={css.closeIcon} />
                            </Box>
                          )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {footerGroups && (
            <tfoot>
              {footerGroups.map((group: any, i: number) => (
                <tr key={i} {...group.getFooterGroupProps()}>
                  {group.headers.map((column: any, index: number) => (
                    <th
                      style={css.tableHeadCell}
                      key={index}
                      {...column.getFooterProps()}
                    >
                      {column.render("Footer")}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </Box>
      {footerGroups && <Box sx={css.footerBack as SxProps} />}
      {data && data.length === 0 && (
        <Box sx={css.emptyTextContainer}>
          <Typography sx={css.emptyText}>Нет данных</Typography>
        </Box>
      )}
    </Box>
  );
};

export default BaseTable;

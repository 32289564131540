import {
  Box,
  CircularProgress,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ReactComponent as ArrowLeft } from "ASSETS/svg/arrow/smallArrowLeft.svg";
import React, { FC, Fragment, useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Header } from "SRC/components/Header/Header";
import { Layout } from "SRC/layouts";
import { Content, Overlay, RootContainer } from "SRC/pages/App.styled";
import { useAppDispatch, useAppSelector } from "SRC/redux/hooks";
import { useGosprogram } from "SRC/redux/slices/gosprogram/hooks/useGosprogram";
import { useProgramEvents } from "SRC/redux/slices/gosprogram/hooks/useProgramEvents";
import { useProgramFilters } from "SRC/redux/slices/gosprogram/hooks/useProgramFilters";
import { useProgramGoals } from "SRC/redux/slices/gosprogram/hooks/useProgramGoals";
import { useStatistics } from "SRC/redux/slices/gosprogram/hooks/useStatistics";
import { actions as programEventsActions } from "SRC/redux/slices/gosprogram/slices/programEvents";
import { actions } from "SRC/redux/slices/gosprogram/slices/programGoals";
import { actions as statisticsActions } from "SRC/redux/slices/gosprogram/slices/statistics";
import { actions as structureElements } from "SRC/redux/slices/gosprogram/slices/structureElements";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { useGoals } from "SRC/redux/slices/main/hooks/useGoals";
import { usePrograms } from "SRC/redux/slices/main/hooks/usePrograms";
import themeConfig from "SRC/theme";

import { CenterLayout, LeftLayout, RightLayout } from "./layouts";
import { css } from "./Program.styled";

export const Program: FC = () => {
  const theme = useTheme<typeof themeConfig>();
  const isExtraLarge = useMediaQuery(theme.breakpoints.up("xl"));

  const { user } = useAppSelector((state) => state.auth.user);
  const { loading, init, loaded } = useGosprogram();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { items: programGoals } = useProgramGoals();
  const { items: events } = useProgramEvents();
  const { items: programs } = usePrograms();
  const { items: statistics } = useStatistics();
  const { items: goals } = useGoals();
  const { setSelectedStatus, setSelectedEventFeature } = useProgramFilters();
  const { setCalculationMethod }: IFilters = useFilters();

  useEffect(() => {
    if (user && !loading && !loaded && id) {
      init({
        id: id,
        fp_code: "*",
        dp_code: "*",
        cpm_code: "*",
      });
    }
  }, [init, loaded, loading, id]);

  useEffect(() => {
    if (id && user) {
      dispatch(actions.get(id));
      dispatch(
        programEventsActions.get({
          id: id,
          fp_code: "*",
          dp_code: "*",
          cpm_code: "*",
        })
      );
      dispatch(statisticsActions.get(id));
      dispatch(structureElements.get(id));
    }
    setSelectedStatus("");
    setSelectedEventFeature("");
    setCalculationMethod("effect");
  }, [id, user, dispatch]);

  const programInfo = useMemo(
    () => programs?.filter((item) => item.gp_code === id)[0],
    [programs, id]
  );

  if (!loaded) {
    return (
      <RootContainer
        sx={{
          width: "100vw",
          height: "100vh",
        }}
      >
        <Header />
        <Content>
          <Overlay>
            <CircularProgress />
          </Overlay>
        </Content>
      </RootContainer>
    );
  }

  return (
    <Fragment>
      <Box sx={css.backWrapper}>
        <Box sx={css.backContainer} onClick={() => navigate(-1)}>
          <ArrowLeft style={css.arrow} />
          <Typography sx={css.backText as SxProps}>назад</Typography>
        </Box>
      </Box>
      <Layout
        left={<LeftLayout />}
        center={
          <CenterLayout
            programGoals={programGoals}
            programInfo={programInfo}
            statistics={statistics}
            events={events}
            goals={goals}
            isExtraLarge={isExtraLarge}
          />
        }
        right={
          isExtraLarge && <RightLayout programGoals={[]} events={events} />
        }
        isProgramLayout
      />
    </Fragment>
  );
};

import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { RatingTop } from "SRC/components/RatingTop/RatingTop";
import {
  IRatingTopBestData,
  IRatingTopWorstData,
} from "SRC/pages/Summary/common/Rating/util/interfaces";

import { css } from "./RatingTab.styled";

interface IRatingTabProps {
  data: IRatingTopBestData[] | IRatingTopWorstData[];
  withDarkBgr?: boolean | undefined;
  typeTop?: string;
}

export const RatingTab = ({ data, withDarkBgr, typeTop }: IRatingTabProps) => {
  return (
    <Grid sx={css.container}>
      {withDarkBgr && (
        <Box>
          <Typography component="h3" sx={css.title}>
            {typeTop === "best" ? "Топ 5 лучших" : "Топ 5 худших"}
          </Typography>
        </Box>
      )}
      <Box sx={css.rating}>
        {data?.map((item) => (
          <RatingTop data={item} withDarkBgr={withDarkBgr} />
        ))}
      </Box>
    </Grid>
  );
};

import { Box, Typography } from "@mui/material";
import React from "react";
import {
  IRatingTopBestData,
  IRatingTopWorstData,
} from "SRC/pages/Summary/common/Rating/util/interfaces";

import { css } from "./Rating.styled";
import { RatingTabs } from "./RatingTabs/RatingTabs";

interface IRatingProps {
  topBestData: IRatingTopBestData[];
  topWorstData: IRatingTopWorstData[];
}
export const Rating = ({ topBestData, topWorstData }: IRatingProps) => {
  return (
    <Box>
      <Box>
        <Typography variant="h1" component={"h1"}>
          Рейтинг эффективности
        </Typography>
      </Box>
      <Box sx={css.ratingContainer}>
        <RatingTabs topWorstData={topWorstData} topBestData={topBestData} />
      </Box>
    </Box>
  );
};

import { IPeriod } from "SRC/redux/slices/main/slices/filters";
import { IStatParams } from "SRC/redux/slices/main/slices/stats";

import { olapRequestData2, olapRequestData4 } from "../../../../core/api/core";
import { MODEL_UUID, SECTION_NAMES } from "../../../../core/scheme/olap";

export const getPrograms = (period: IPeriod) => {
  return olapRequestData4([
    {
      requestId: "fd2870d5-8654-46d1-a809-0ec9fb9d5ca7",
      type: "data2",
      params: {
        modelUuid: "c116bb29-cc0d-4f3a-a050-392dd7f41db8",
        dashboardUuid: "",
        includeGaps: false,
        dimensions: [
          {
            id: "1562",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          {
            id: "1565",
            includeItems: true,
            includeAttributesByCodes: [
              "NAME",
              "SHORT_NAME",
              "SORT_ORDER",
              "gp_short_name",
              "Вице-премьер",
              "gp_name",
              "Министерство",
            ],
          },
        ],
        indicators: {
          id: "1571",
          items: [
            {
              id: "1578",
              aggregationFunction: "sum",
            },
            {
              id: "1579",
              aggregationFunction: "sum",
            },
            {
              id: "1580",
              aggregationFunction: "sum",
            },
            {
              id: "1584",
              aggregationFunction: "sum",
            },
            {
              id: "1585",
              aggregationFunction: "sum",
            },
            {
              id: "1586",
              aggregationFunction: "sum",
            },
            {
              id: "1590",
              aggregationFunction: "sum",
            },
            {
              id: "1753",
              aggregationFunction: "sum",
            },
            {
              id: "1754",
              aggregationFunction: "sum",
            },
            {
              id: "1755",
              aggregationFunction: "sum",
            },
            {
              id: "1756",
              aggregationFunction: "sum",
            },
            {
              id: "1757",
              aggregationFunction: "sum",
            },
            {
              id: "1796",
              aggregationFunction: "sum",
            },
          ],
        },
        dataRequestCaching: true,
        dataSort: [
          {
            dim_id: "1562",
            sortField: "name",
            sortOrder: "asc",
          },
          {
            dim_id: "1565",
            sortField: "name",
            sortOrder: "asc",
          },
        ],
        dataFilter: {
          type: "AND",
          version: 1,
          operands: [
            {
              type: "STARTER",
              version: 1,
              dim_id: "1565",
            },
            {
              type: "EQ",
              version: 1,
              operands: [
                {
                  type: "DIM",
                  version: 1,
                  id: "1562",
                },
                {
                  type: "CONST",
                  version: 1,
                  values: [period.filterValue.slice(0, 4)],
                },
              ],
            },
          ],
        },
      },
    },
  ]);
};

export const getStats = (params: IStatParams) => {
  return olapRequestData2({
    dimensions: [],
    indicators: {},
    section_name: SECTION_NAMES.STATS,
    modelUuid: MODEL_UUID,
    filters: {
      period_code: params.period.filterValue,
      gp_code: params.gp_code,
      vp_code: params.vp_code,
    },
  });
};

export const getGoals = (period: IPeriod) => {
  return olapRequestData2({
    dimensions: [],
    indicators: {},
    section_name: SECTION_NAMES.GOALS,
    modelUuid: MODEL_UUID,
    filters: {
      period_code: period.filterValue,
    },
  });
};

export const getPremiers = (period: IPeriod) => {
  return olapRequestData4([
    {
      requestId: "46c4caf5-da1a-48ba-898b-79a6d6196bd6",
      type: "data2",
      params: {
        modelUuid: "f8cfb9f5-ce0b-4b19-86c5-fd5a55d97a77",
        dashboardUuid: "",
        includeGaps: false,
        dimensions: [
          {
            id: "1683",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          {
            id: "1686",
            includeItems: true,
            includeAttributesByCodes: [
              "NAME",
              "SHORT_NAME",
              "SORT_ORDER",
              "VP_PHOTO_URL",
              "VP_NAME",
            ],
          },
        ],
        indicators: {
          id: "1692",
          items: [
            {
              id: "1742",
              aggregationFunction: "sum",
            },
            {
              id: "1743",
              aggregationFunction: "sum",
            },
            {
              id: "1744",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1747",
              aggregationFunction: "sum",
            },
            {
              id: "1748",
              aggregationFunction: "sum",
            },
            {
              id: "1749",
              aggregationFunction: "sum",
            },
            {
              id: "1767",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1768",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1797",
              aggregationFunction: "sum",
            },
          ],
        },
        dataRequestCaching: true,
        dataSort: [
          {
            dim_id: "1683",
            sortField: "name",
            sortOrder: "asc",
          },
          {
            dim_id: "1686",
            sortField: "name",
            sortOrder: "asc",
          },
        ],
        dataFilter: {
          type: "AND",
          version: 1,
          operands: [
            {
              type: "STARTER",
              version: 1,
              dim_id: "1686",
            },
            {
              type: "EQ",
              version: 1,
              operands: [
                {
                  type: "DIM",
                  version: 1,
                  id: "1683",
                },
                {
                  type: "CONST",
                  version: 1,
                  values: [period.filterValue.slice(0, 4)],
                },
              ],
            },
          ],
        },
      },
    },
  ]);
};

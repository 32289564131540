import { Box, CircularProgress, Modal, SxProps } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Overlay } from "SRC/pages/App.styled";
import { css } from "SRC/pages/Program/common/EventCard/EventTabs/ObjectTab/ObjectTab.styled";
import { PhotosModal } from "SRC/pages/Program/common/EventCard/EventTabs/ObjectTab/PhotosModal/PhotosModal";
import { useObjectPhotos } from "SRC/redux/slices/gosprogram/hooks/useObjectPhotos";
import { useProgramFilters } from "SRC/redux/slices/gosprogram/hooks/useProgramFilters";
import { actions as objectPhotosActions } from "SRC/redux/slices/gosprogram/slices/objectPhotos";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";

interface IPhotosModalWrapperProps {
  isPhotoModalOpen: boolean;
  handlePhotoModalClose(): void;
}

export const PhotosModalWrapper = ({
  isPhotoModalOpen,
  handlePhotoModalClose,
}: IPhotosModalWrapperProps) => {
  const { period }: IFilters = useFilters();
  const { fetching, fetched, load, error } = useObjectPhotos();
  const dispatch = useDispatch();
  const { selectedObjectId } = useProgramFilters();

  useEffect(() => {
    if (!fetching && !fetched && !error && selectedObjectId) {
      load({
        id: selectedObjectId,
        period: period.filterValue,
      });
    }
  }, [fetching, fetched, error, period, selectedObjectId]);

  useEffect(() => {
    dispatch(
      objectPhotosActions.get({
        id: selectedObjectId,
        period: period.filterValue,
      })
    );
  }, [dispatch, selectedObjectId, period]);

  return (
    <Modal
      open={isPhotoModalOpen}
      onClose={handlePhotoModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={css.modalContainer as SxProps}>
        {!fetched ? (
          <Box sx={{ width: "100%", height: "100%" }}>
            <Overlay>
              <CircularProgress />
            </Overlay>
          </Box>
        ) : (
          <PhotosModal handlePhotoModalClose={handlePhotoModalClose} />
        )}
      </Box>
    </Modal>
  );
};

import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import {
  ICordsItemLeft,
  ICordsItemRight,
} from "SRC/pages/Program/common/NationalGoalsConnect/NationalGoalsConnect";
import { useAppSelector } from "SRC/redux/hooks";
import { useProgramFilters } from "SRC/redux/slices/gosprogram/hooks/useProgramFilters";

interface IConnectorLineProps {
  cordsLeft: ICordsItemLeft[];
  cordsRight: ICordsItemRight[];
  widthCanvas: number | undefined;
  heightCanvas: number | undefined;
  activeFilter: boolean;
  hiddenCountItemsRef: MutableRefObject<number[]>;
}

export const ConnectorLine = ({
  cordsLeft,
  cordsRight,
  widthCanvas,
  heightCanvas,
  activeFilter,
  hiddenCountItemsRef,
}: IConnectorLineProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const {
    selectedProgramGoalId,
    selectedProgramGoalFactorsId,
    selectedHiddenFactorsId,
    selectedGoalId,
    selectedGoalFactorsId,
  } = useAppSelector((state) => state.gosprogram.programFilters);
  const arrCoordinates = useMemo(() => {
    let arrayCords: any = [];
    cordsLeft.map((itemLeft: ICordsItemLeft, indexLeft) => {
      cordsRight.map((itemRight: ICordsItemRight, indexRight) => {
        if (itemLeft.dataInfo.goalsCode.includes(itemRight.dataInfo.id)) {
          itemRight.dataInfo.factors.map((factor, index) => {
            if (factor?.programId?.includes(itemLeft?.dataInfo?.id)) {
              arrayCords.push({
                programGoalId: itemLeft.dataInfo.id,
                goalCode: itemRight.dataInfo.id,
                goalFactor: factor.idFactor,
                cordOut: {
                  x: itemLeft.x,
                  y: itemLeft.y,
                },
                cordIn: {
                  x: itemRight.x,
                  y: itemRight.y,
                },
                itemHeightRight: itemRight?.heightItem,
                itemHeightLeft: itemLeft?.heightItem,
              });
            }
          });
        }
      });
    });
    return arrayCords;
  }, [
    cordsRight,
    cordsLeft,
    widthCanvas,
    activeFilter,
    selectedHiddenFactorsId,
    selectedProgramGoalId,
    selectedGoalId,
  ]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas && widthCanvas && heightCanvas) {
      canvas.width = widthCanvas;
      canvas.height = heightCanvas;
    }
    if (canvas === null) return;
    const ctx = canvas.getContext("2d");
    if (ctx === null) return;
    ctx.fillStyle = "#212735";
    ctx.fillRect(0, 0, ctx.canvas.width, canvas.height);

    const filteredArrayCords = arrCoordinates.filter((item: any) => {
      let isVisibleFactors = !selectedHiddenFactorsId.includes(item.goalFactor); // только при условии выбранной цели
      let selectedFactors = selectedProgramGoalFactorsId.concat(
        selectedGoalFactorsId
      );
      let selectedProgram = selectedProgramGoalId
        ? selectedProgramGoalId === item.programGoalId
        : true;
      return selectedFactors.length
        ? selectedFactors.includes(item.goalFactor) &&
            isVisibleFactors &&
            selectedProgram
        : isVisibleFactors;
    });

    ctx.beginPath();

    if (widthCanvas && heightCanvas) {
      filteredArrayCords.forEach((item: any, index: number) => {
        let pointLeftX = 0;
        let pointLeftY = 0;
        let pointRightX = 0;
        let pointRightY = 0;
        let cp1 = {
          x: widthCanvas / 2,
          y: item?.cordOut?.y + item?.itemHeightLeft / 2,
        };
        let cp2 = {
          x: widthCanvas / 2,
          y: item?.cordIn?.y + item?.itemHeightRight / 2,
        };
        if (item?.cordOut?.y < 0) {
          cp1 = { x: widthCanvas / 2, y: 0 };
        } else if (item?.cordOut?.y > ctx?.canvas?.height - 60) {
          pointLeftY = ctx?.canvas?.height;
          cp1 = {
            x: widthCanvas / 2,
            y: ctx?.canvas?.height,
          };
        } else {
          pointLeftY = item?.cordOut?.y + item?.itemHeightLeft / 2;
        }
        pointRightX = ctx?.canvas?.width;
        pointRightY = item?.cordIn?.y + item?.itemHeightRight / 2;
        ctx.moveTo(pointLeftX, pointLeftY);
        ctx.bezierCurveTo(cp1.x, cp1.y, cp2.x, cp2.y, pointRightX, pointRightY);
      });
    }
    ctx.strokeStyle = "white";
    ctx.lineWidth = 2;
    ctx.stroke();
  }, [
    cordsRight,
    cordsLeft,
    widthCanvas,
    heightCanvas,
    activeFilter,
    selectedHiddenFactorsId,
    selectedProgramGoalId,
    selectedGoalId,
  ]);

  hiddenCountItemsRef.current = useMemo(() => {
    let counter: any = [0, 0];
    const canvas = canvasRef.current;
    if (canvas === null) return counter;
    const ctx = canvas.getContext("2d");
    if (ctx === null) return counter;
    cordsLeft.map((item: any) => {
      if (item?.y < 0) {
        counter[0] = counter[0] + 1;
      } else if (item?.y > ctx.canvas.height - 60) {
        counter[1] = counter[1] + 1;
      }
    });
    return counter;
  }, [
    cordsRight,
    cordsLeft,
    widthCanvas,
    activeFilter,
    selectedHiddenFactorsId,
    selectedProgramGoalId,
    selectedGoalId,
  ]);

  return <canvas id="canvas" ref={canvasRef}></canvas>;
};

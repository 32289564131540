import { Box, Grid, SxProps, Typography, useTheme } from "@mui/material";
import closeIcon from "ASSETS/svg/close/closeIcon.svg";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { Column, useGlobalFilter, useSortBy, useTable } from "react-table";
import Select from "SRC/components/Select/Select";
import { BaseTable } from "SRC/components/Table";
import TableStatusCard from "SRC/components/TableStatusCard/TableStatusCard";
import { TableSearch } from "SRC/pages/Program/common/EventsSection/EventsTable/TableSearch/TableSearch";
import { IEventItem } from "SRC/pages/Program/common/EventsSection/util/interfaces";
import { ModalStructure } from "SRC/pages/Program/common/Modals/ModalStructure/ModalStructure";
import { IStatus } from "SRC/pages/Program/common/ProgramSummary/interfaces";
import { useAppSelector } from "SRC/redux/hooks";
import { useProgramFilters } from "SRC/redux/slices/gosprogram/hooks/useProgramFilters";
import { useStructureElements } from "SRC/redux/slices/gosprogram/hooks/useStructureElements";
import { IStructureElement } from "SRC/types";

import {
  ButtonGridContainer,
  ButtonStyled,
  css,
  SelectedElemFilterContainer,
  SelectedElemInputContainer,
  SelectedElemInputImage,
  SelectedElemInputText,
  SelectedElemLabel,
  StatusCardsContainer,
  TableContainer,
} from "./EventsTable.styled";

interface IProps {
  columns: Column[];
  data: IEventItem[];
  isFilterModalOpen: boolean;
  setIsFilterModalOpen: Dispatch<SetStateAction<boolean>>;
  openTableModal?: boolean;
  setEventModalMode?: Dispatch<
    SetStateAction<"default" | "eventCard" | "eventsFilter">
  >;
  eventModalMode?: "default" | "eventCard" | "eventsFilter";
  withoutButton?: boolean;
  setOpenEventCard?: Dispatch<SetStateAction<boolean>>;
  statuses: IStatus[];
}

const selectOptions = [
  { value: "Признак_основные", label: "Признак основные" },
  { value: "Признак_не основные", label: "Признак не основные" },
  { value: "Признак_ОКС онлайн", label: "Признак ОКС онлайн" },
  {
    value: "Признак_Реализация в субъектах",
    label: "Признак Реализация в субъектах",
  },
  { value: "Признак_СЗП", label: "Признак СЗП" },
  { value: "Признак_ФХД", label: "Признак ФХД" },
  { value: "Признак_КТ", label: "Признак КТ" },
  { value: "Признак_ОКС", label: "Признак ОКС" },
  { value: "Признак_ЛЮГ", label: "Признак ЛЮГ" },
  { value: "Признак_СМНП", label: "Признак СМНП" },
];

export const EventsTable = (props: IProps) => {
  const {
    columns,
    data,
    isFilterModalOpen,
    setIsFilterModalOpen,
    openTableModal,
    setEventModalMode,
    eventModalMode,
    withoutButton,
    setOpenEventCard,
    statuses,
  } = props;

  const theme = useTheme();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setSortBy,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );
  const { globalFilter } = state;

  const {
    setSelectedStructureRow,
    selectedEventFeature,
    setSelectedEventFeature,
    setSelectedStructureId,
  } = useProgramFilters();
  const { selectedStructureId, selectedStructureRow } = useAppSelector(
    (state) => state.gosprogram.programFilters
  );
  const { items: structures } = useStructureElements();

  const buttonDisabled =
    data.length < 2 && !selectedStructureId && !selectedStructureRow;

  const structureElement = useMemo<IStructureElement | undefined>(
    () => structures.find((item) => item.code === selectedStructureId),
    [selectedStructureId, structures]
  );

  const [isHintVisible, setHintVisible] = useState(false);

  const onPointerEnter = () => setHintVisible(true);
  const onPointerLeave = () => setHintVisible(false);

  const handleButtonClick = () => {
    setSelectedStructureRow(selectedStructureId);
    if (!buttonDisabled) {
      if (openTableModal) {
        setEventModalMode?.("eventsFilter");
      }
      if (!isFilterModalOpen && !openTableModal) {
        setIsFilterModalOpen(true);
      }
    }
  };

  const handleCloseClick = () => {
    setIsFilterModalOpen(false);
    setSelectedStructureRow("");
    setSelectedStructureId("");
  };

  const { selectedStatus, setSelectedStatus } = useProgramFilters();

  const statusesNode: JSX.Element[] = useMemo(() => {
    return statuses.map((item, i) => (
      <TableStatusCard key={i} status={item.status} amount={item.amount} />
    ));
  }, [selectedStatus, setSelectedStatus]);

  return (
    <Box style={{ paddingTop: "2rem" }}>
      <Grid
        container
        justifyContent="space-between"
        gap={"1rem"}
        sx={{ alignItems: "center" }}
      >
        <Grid item xs={3} lg={2} xl={3} style={{ flex: 1 }}>
          <TableSearch
            dataLength={data.length}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </Grid>
        <Grid item xs={6} lg={8} xl={9}>
          <StatusCardsContainer>{statusesNode}</StatusCardsContainer>
        </Grid>
        {!withoutButton && (
          <ButtonGridContainer item xs={3} lg={2}>
            <ButtonStyled
              onClick={handleButtonClick}
              disabled={buttonDisabled}
              onPointerEnter={onPointerEnter}
              onPointerLeave={onPointerLeave}
            >
              Выбор структурного элемента
            </ButtonStyled>
            {structureElement && isHintVisible && (
              <Box sx={css.btnHintContainer as SxProps}>
                <Typography sx={css.hint as SxProps}>
                  {structureElement.name}
                </Typography>
              </Box>
            )}
            {structureElement && (
              <SelectedElemFilterContainer>
                <SelectedElemLabel>
                  Структурный элемент:{" "}
                  <b style={{ color: theme.palette.main.gray }}>
                    {structureElement["Признак, ВП"]
                      ? "Ведомственные проекты"
                      : structureElement["Признак, ФП"]
                      ? "Федеральные проекты"
                      : structureElement["Признак, КПМ"]
                      ? "Комплексы процессных мероприятий"
                      : ""}
                  </b>
                </SelectedElemLabel>
                <SelectedElemInputContainer>
                  <SelectedElemInputText noWrap>
                    {structureElement.name}
                  </SelectedElemInputText>
                  <SelectedElemInputImage
                    src={closeIcon}
                    alt="close"
                    onClick={handleCloseClick}
                  />
                </SelectedElemInputContainer>
              </SelectedElemFilterContainer>
            )}
            <Select
              options={selectOptions}
              value={selectedEventFeature}
              onChange={(value: string) => setSelectedEventFeature(value)}
            />
            <ModalStructure
              open={isFilterModalOpen}
              setOpen={setIsFilterModalOpen}
              statuses={statuses}
            />
          </ButtonGridContainer>
        )}
      </Grid>
      <TableContainer>
        <BaseTable
          columns={columns}
          data={data}
          sortedColumns={["План", "Факт", "Выполнение", "Влияние"]}
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
          setSortBy={setSortBy}
          tableHeight={openTableModal ? "calc(100vh - 27rem)" : "47.5rem"}
          isEventModal={true}
          eventModalMode={eventModalMode}
          setEventModalMode={setEventModalMode}
          openTableModal={openTableModal}
          setOpenEventCard={setOpenEventCard}
        />
      </TableContainer>
    </Box>
  );
};

import { Grid, SxProps, Theme, Typography } from "@mui/material";
import { FC } from "react";

import {
  CardContent,
  CardWrapDiv,
  HeaderLeft,
  HeaderRight,
} from "./CardWrap.styled";

interface Props {
  sx?: SxProps;
  headerLeft?: string;
  headerRight?: string;
}

export const CardWrap: FC<Props> = ({ children, headerLeft, headerRight }) => {
  return (
    <CardWrapDiv>
      <Grid
        container
        sx={{
          borderBottom: (theme: Theme) =>
            `3px dotted ${theme.palette.main.darkBlue}`,
          justifyContent: "space-between",
        }}
      >
        {headerLeft && (
          <Typography sx={HeaderLeft} component="div" color={"text.primary"}>
            {headerLeft}
          </Typography>
        )}
        {headerRight && (
          <Typography sx={HeaderRight} component="div" color={"text.secondary"}>
            {headerRight}
          </Typography>
        )}
      </Grid>
      <CardContent>{children}</CardContent>
    </CardWrapDiv>
  );
};

import { Theme } from "@mui/material";

import { Data } from "./ DividedDoughnutChart";

export const getOption = (theme: Theme, data: Data[]) => ({
  grid: {
    borderWidth: 0,
    width: "100%",
    height: "100%",
    left: "0",
    bottom: "0",
  },
  tooltip: {
    show: false,
  },
  series: [
    {
      type: "pie",
      radius: ["88%", "95%"],
      avoidLabelOverlap: false,
      itemStyle: {
        borderColor: theme.palette.background.default,
        borderWidth: 2,
        borderRadius: theme.shape.borderRadius,
      },
      label: {
        show: false,
        position: "center",
      },
      emphasis: {
        scale: false,
      },
      labelLine: {
        show: false,
      },
      emptyCircleStyle: true,
      data,
    },
  ],
});

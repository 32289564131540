import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProgramGoals } from "SRC/redux/slices/gosprogram/api/client";
import { IProgramGoal } from "SRC/types";

export interface IProgramGoalsState {
  items: IProgramGoal[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "programGoals/get",
    async (id: string | number) => await getProgramGoals(id)
  ),
};

const initialState: IProgramGoalsState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const isIProgramGoal = (array: any): array is IProgramGoal[] => !!array?.length;

const slice = createSlice({
  name: "programGoals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: IProgramGoalsState, action) => {
          state.items = isIProgramGoal(action.payload.data)
            ? action.payload.data
            : [];
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;

import { Box, Grid, Typography } from "@mui/material";
import { Doughnut } from "SRC/components/Charts";
import { IPopulationData } from "SRC/types/analitics";

import { CardWrap } from "../CardWrap";
import {
  css,
  populationScreenStatistic,
  populationWrapStatistic,
} from "./Population.styled";
import { PopulationVariant } from "./PopulationVariant";

interface IPopulationProps {
  data: IPopulationData;
}
export const Population = ({ data }: IPopulationProps) => (
  <CardWrap headerLeft="Опросы населения" headerRight="%">
    <Grid
      sx={{ opacity: "0.2" }}
      wrap={"nowrap"}
      justifyContent={"space-between"}
      gap="0.5rem"
      container
    >
      <Grid item sx={css.chartContainer}>
        <Doughnut success={data.percent || 0} />
      </Grid>
      <Grid flexGrow={"1"} item>
        <Grid flexWrap={"nowrap"} sx={populationWrapStatistic}>
          <Grid sx={populationScreenStatistic} flexWrap="nowrap" container>
            <Grid sx={css.variantWrapper}>
              <Typography sx={css.variantTitle} variant="h4" component="div">
                Удовлетворенность результатами ГП
              </Typography>
              <Typography sx={css.variantSubtitle} variant="h3" component="div">
                %
              </Typography>
              <Grid sx={css.variantContainer} container>
                <PopulationVariant
                  variant={true}
                  count={String(data.positive)}
                />
                <PopulationVariant
                  variant={false}
                  count={String(data.negative)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </CardWrap>
);

import { Box, Button, Grid, SxProps, useTheme } from "@mui/material";
import { CheckpointArrow } from "ASSETS/svg/arrow";
import { useMemo, useState } from "react";
import React from "react";
import { StepComponent } from "SRC/components/Checkpoints/Step/Step";
import { IStepsInfo } from "SRC/pages/Program/common/EventCard/util/interfaces";
import { ICheckpoint } from "SRC/redux/slices/gosprogram/slices/controlPoints";
import themeConfig from "SRC/theme";

import { BoxConnector, BoxStepper, css } from "./Checkpoint.styled";

interface IProps {
  checkpoints: ICheckpoint[];
}

export const Checkpoints = ({ checkpoints }: IProps) => {
  const theme = useTheme<typeof themeConfig>();

  const checkpointsData = useMemo<IStepsInfo[]>(
    () =>
      checkpoints
        ?.map((item: ICheckpoint) => ({
          datePlan: item.data.find((el) => el.code === "1380")?.sum || " - ",
          dateFinish:
            item.data.find((el) => el.code === "1381")?.text ||
            item.data.find((el) => el.code === "1381")?.sum ||
            " - ",
          namePoint: item.NAME,
          statusPoint:
            item.data.find((el) => el.code === "1504")?.sum === "R"
              ? "red"
              : item.data.find((el) => el.code === "1504")?.sum === "Gr"
              ? "gray"
              : item.data.find((el) => el.code === "1504")?.sum === "G"
              ? "green"
              : "default",
          SORT_ORDER: Number(item.SORT_ORDER),
        }))
        .sort((a, b) => a.SORT_ORDER - b.SORT_ORDER),
    [checkpoints]
  );

  const [currentStep, setCurrenStep] = useState(0);
  const [openName, setOpenName] = useState<boolean>(false);

  const handleNext = () => {
    if (currentStep < checkpointsData.length)
      setCurrenStep((state) => state + 1);
  };
  const handleBack = () => {
    if (currentStep > 0) setCurrenStep((state) => state - 1);
  };
  const steps = useMemo(
    () =>
      checkpointsData.map((label, index) => (
        <StepComponent
          key={index}
          label={label}
          openName={openName}
          setOpenName={setOpenName}
        />
      )),
    [checkpointsData, openName]
  );
  const isPrevBtnDisabled = currentStep === 0;
  const isNextBtnDisabled = currentStep === checkpointsData.length;

  return (
    <Box sx={css.wrapper as SxProps}>
      <Grid container sx={css.legendContainer}>
        <Grid item sx={{ display: "flex", gap: "1rem" }}>
          <p>Всего </p>
          <p className="white">{checkpointsData.length}</p>
        </Grid>
        <Grid item sx={css.legendItemContainer}>
          <Box sx={css.styledPoint} className="green" />
          <p>Выполнено </p>
          <p className="white">
            {checkpointsData.filter((el) => el.statusPoint === "green").length}
          </p>
        </Grid>
        <Grid item sx={css.legendItemContainer}>
          <Box sx={css.styledPoint} className="red" />
          <p>Не выполнено </p>
          <p className="white">
            {checkpointsData.filter((el) => el.statusPoint === "red").length}
          </p>
        </Grid>
        <Grid item sx={css.legendItemContainer}>
          <Box sx={css.styledPoint} className="gray" />
          <p>В реализации </p>
          <p className="white">
            {checkpointsData.filter((el) => el.statusPoint === "gray").length}
          </p>
        </Grid>
        <Grid item sx={css.legendItemContainer}>
          <Box sx={css.styledPoint} className="dark" />
          <p>Не представлена отчетность </p>
          <p className="white">
            {
              checkpointsData.filter((el) => el.statusPoint === "default")
                .length
            }
          </p>
        </Grid>
      </Grid>
      <Box sx={css.stepperContainer(theme, openName) as SxProps}>
        <BoxStepper
          activeStep={4}
          connector={
            <Box
              sx={css.connectorWrapper as SxProps}
              className="connectorWrapper"
            >
              <BoxConnector />
            </Box>
          }
          sx={{ left: `${currentStep * -35}rem` }}
          alternativeLabel
        >
          {steps}
        </BoxStepper>
        <Button
          sx={css.button as SxProps}
          onClick={handleBack}
          style={{ left: "1rem" }}
          disabled={isPrevBtnDisabled}
        >
          <CheckpointArrow style={css.arrowLeft(isPrevBtnDisabled)} />
        </Button>
        <Button
          sx={css.button as SxProps}
          onClick={handleNext}
          style={{ right: "1rem" }}
          disabled={isNextBtnDisabled}
        >
          <CheckpointArrow style={css.arrowRight(isNextBtnDisabled)} />
        </Button>
        <Box sx={css.checkpointStepperLine} />
      </Box>
    </Box>
  );
};

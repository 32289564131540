import {
  Box,
  Divider,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import ReactECharts from "echarts-for-react";
import React, { useMemo } from "react";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import themeConfig from "SRC/theme";

import { css } from "./ DividedDoughnutChart.styled";
import { getOption } from "./options";

export interface Data {
  value: number;
  itemStyle: any;
}

interface IAreaOptions {
  plan: number;
  fact: number;
}

interface IProps {
  values: IAreaOptions;
  areas: IAreaOptions[];
  colors?: string[];
}

const DividedDoughnutChart = ({ values, areas, colors = [] }: IProps) => {
  const theme = useTheme<typeof themeConfig>();
  const totalFact = areas.reduce((acc, item) => acc + item.plan, 0);

  const chartsAreas = useMemo(() => {
    const data: Data[] = [];
    areas.forEach((item, key) => {
      let color;
      if (colors.length) {
        color = colors[key % colors.length];
      } else {
        color =
          key % 2 === 0 ? theme.palette.text.onAccent : theme.palette.main.blue;
      }

      const availableArea = (item.plan * 100) / totalFact;
      const percent = (item.fact * availableArea) / item.plan;
      data.push({
        value: percent,
        itemStyle: {
          color,
          borderRadius: [100, 0, 100, 0],
        },
      });
      data.push({
        value: availableArea - percent,
        itemStyle: {
          color: theme.palette.complementary.darkBlue,
          borderRadius: [0, 100, 0, 100],
        },
      });
    });

    return data;
  }, [theme, areas, totalFact, colors]);

  const options = useMemo(
    () => getOption(theme, chartsAreas),
    [theme, chartsAreas]
  );

  return (
    <Box sx={css.container}>
      <Stack
        sx={css.textContainer as SxProps}
        divider={<Divider flexItem />}
        spacing={"5px"}
      >
        <Typography sx={css.text}>
          {String((values.fact && roundNumbersToFixed(values.fact, 1)) || 0)}
        </Typography>
        <Typography sx={css.grayText}>
          {String((values.plan && roundNumbersToFixed(values.plan, 1)) || 0)}
        </Typography>
      </Stack>
      <ReactECharts option={options} style={css.graph} />
    </Box>
  );
};

export default DividedDoughnutChart;

import { Box, SxProps, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { RectangleWithCircle } from "SRC/components/Cards";
import { CarouselItem } from "SRC/components/Carousel/Carousel";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { TProgram, usePrograms } from "SRC/redux/slices/main/hooks/usePrograms";
import themeConfig from "SRC/theme";

import { css } from "./Tiles.styled";
import TilesItemElement from "./TilesItemElement";

export default function Tiles() {
  const { items } = usePrograms();
  const {
    program,
    filteredPrograms,
    setSelectedProgram,
    vp,
    setSelectedGoal,
    period,
    calculationMethod,
  }: IFilters = useFilters();

  const navigate = useNavigate();

  const toggleSelection = useCallback(
    (code: typeof program) => () => {
      if (!filteredPrograms.includes(code)) {
        setSelectedGoal("");
      }
      setSelectedProgram(program === code ? "" : code);
    },
    [program, setSelectedProgram, setSelectedGoal, filteredPrograms]
  );

  const theme = useTheme<typeof themeConfig>();
  const xl = useMediaQuery(theme.breakpoints.up("xl"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const visibleItems = (xl && 7) || (lg && 4) || (md && 6) || 4;

  const programsCards = useMemo(
    (): JSX.Element[] =>
      items
        .filter(({ "Вице-премьер, код": vp_code }: TProgram) => vp_code === vp)
        .map((item: TProgram) => (
          <CarouselItem
            visibleItems={visibleItems}
            style={{ paddingLeft: "2rem", cursor: "pointer" }}
            onClick={toggleSelection(item.gp_code as typeof program)}
            onDoubleClick={() => navigate(`/program/${item.gp_code}`)}
          >
            <RectangleWithCircle
              active={
                program
                  ? program === item.gp_code
                  : filteredPrograms.includes(item.gp_code)
              }
              name={item.gp_short_name}
              icon={item.gp_icon_url}
              programId={item.gp_code}
              achievementLevel={
                item.data.find((el) => el.code === "1753")?.sum || 0
              }
              plan={item.data.find((el) => el.code === "1584")?.sum || 0}
              fact={item.data.find((el) => el.code === "1679")?.sum || 0}
            />
          </CarouselItem>
        )),
    [
      items,
      filteredPrograms,
      program,
      visibleItems,
      vp,
      navigate,
      toggleSelection,
      period,
    ]
  );

  const programs = useMemo(
    (): JSX.Element[] =>
      items.map((item: TProgram) => (
        <Box sx={css.elementSX as SxProps} key={item.gp_code}>
          <TilesItemElement
            {...item}
            secretProgram={Number(item["Секретная"])}
            active={
              program
                ? program === item.gp_code
                : filteredPrograms.includes(item.gp_code)
            }
            onClick={toggleSelection(item.gp_code as typeof program)}
          />
        </Box>
      )),
    [
      items,
      filteredPrograms,
      program,
      toggleSelection,
      period,
      calculationMethod,
    ]
  );

  return <Box sx={css.container}>{programs}</Box>;
}

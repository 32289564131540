// import { Button, Theme } from "@mui/material";
// import { styled } from "@mui/material";
//
// export const Control = styled("div")(({ theme }) => ({
//   margin: theme.spacing(1, 0),
//   "& .MuiInputLabel-root": {
//     color: theme.palette.primary.main,
//   },
//   "& .MuiOutlinedInput-notchedOutline": {
//     borderColor: theme.palette.primary.main,
//   },
// }));
// export const SubmitButton = styled(Button)({
//   width: `100%`,
// });

import { Theme } from "@mui/material";

export const css = {
  control: {
    margin: (theme: Theme) => theme.spacing(1, 0),
    "& .MuiInputLabel-root": {
      color: (theme: Theme) => theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: (theme: Theme) => theme.palette.primary.main,
    },
  },
  submit: {
    width: `100%`,
  },
};

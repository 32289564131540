import { Box, Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { bottomCount } from "SRC/components/Cards/Structure/Structure.styled";
import theme from "SRC/theme";

import {
  ContainerWrapper,
  DoneLine,
  LineTrack,
  LineTrackAlt,
  LineWrap,
  LineWrapAlt,
} from "./StructureLineGraph.styled";

interface Props {
  totalItems: number;
  done?: number;
  typeLine?: string;
}

export const StructureLineGraph: FC<Props> = ({
  done,
  totalItems,
  typeLine,
}) => {
  return (
    <Grid container sx={ContainerWrapper}>
      <Box sx={{ flexGrow: "1" }}>
        <Typography
          component="div"
          sx={{
            paddingBottom: "1rem",
            fontSize: "0.8rem",
            color: theme.palette.text.primary,
            [theme.breakpoints.up("md")]: {
              fontSize: "1rem",
            },
          }}
        >
          Мероприятия
        </Typography>
        <Grid justifyContent={"space-between"} alignItems={"end"} container>
          <Typography
            sx={bottomCount}
            component="div"
            color={"primary.contrastText"}
          >
            {done || 0}
          </Typography>
          <Typography sx={bottomCount} component="div" color={"text.secondary"}>
            {totalItems || 0}
          </Typography>
        </Grid>
        {!typeLine ? (
          <LineWrap>
            <LineTrack>
              <DoneLine
                sx={{ width: `${((done || 0) / totalItems) * 100}%` }}
              />
            </LineTrack>
          </LineWrap>
        ) : (
          <LineWrapAlt>
            <LineTrackAlt>
              <DoneLine
                sx={{ width: `${((done || 0) / totalItems) * 100}%` }}
              />
            </LineTrackAlt>
          </LineWrapAlt>
        )}
      </Box>
    </Grid>
  );
};

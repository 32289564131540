import { Theme, Typography, useTheme } from "@mui/material";
import React from "react";
import {
  PopulationBox,
  populationVariantStyles as styles,
} from "SRC/components/Cards/Population/Population.styled";

interface IPopulationVariant {
  variant: boolean;
  count: string;
}

export const PopulationVariant = ({ variant, count }: IPopulationVariant) => {
  const theme = useTheme<Theme>();

  return (
    <PopulationBox sx={styles.wrapper(variant, theme)}>
      <svg width={20} height={20}>
        <circle cx={5} cy={10} r={5} />
      </svg>
      <Typography sx={styles.variant} component="div">
        {variant ? "Да" : "Нет"}
      </Typography>
      <Typography sx={styles.total} variant="h2" component="div">
        {count}
      </Typography>
    </PopulationBox>
  );
};

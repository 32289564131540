import { Theme, Typography } from "@mui/material";
import React, { CSSProperties } from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { Percent } from "SRC/components/elements/Percent/Percent";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";
import {
  css as tableCss,
  TableCellBigText,
  TableFooterText,
} from "SRC/components/Table/BaseTable/BaseTable.styled";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { css } from "SRC/pages/Summary/common/SummaryComplexGrid/SummaryComplexGrid.styled";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>;
}

const getCashColumns = (theme: Theme) => {
  return [
    {
      Header: "План",
      width: css.cashColumn.plan.width,
      accessor: "cashPlan",
      Cell: (tableProps: CustomTableProps) => (
        <TableCellBigText
          align="center"
          sx={css.cashColumn.plan.cellWrapper(
            theme,
            tableProps.row.original.cashPlan
          )}
        >
          {tableProps.row.original.cashPlan}
          <Percent theme={theme} />
        </TableCellBigText>
      ),
      Footer: (tableProps: CustomTableProps) => {
        const total = React.useMemo(() => {
          const rowsArray = Array(tableProps.rows).flat();
          const avarenge = rowsArray.reduce(
            (sum: any, row: any) => row.original.cashPlan + sum,
            0
          );

          return roundNumbersToFixed(avarenge / rowsArray.length, 0) || 0;
        }, [tableProps.rows]);

        return (
          <TableFooterText sx={css.cashColumn.plan.footerWrapper(theme, total)}>
            {total}
            <Percent theme={theme} />
          </TableFooterText>
        );
      },
    },
    {
      Header: "Факт",
      width: css.cashColumn.fact.width,
      accessor: "cashFact",
      Cell: (tableProps: CustomTableProps) => (
        <div style={css.cashColumn.fact.cellWrapper as CSSProperties}>
          <Typography
            sx={tableCss.tableCellText(
              theme,
              tableProps.row.original.statusLabel
            )}
            className="bigText with-right-border cashColumn-fact-cellText"
            align="center"
          >
            {tableProps.row.original.cashFact}
            <Percent theme={theme} />
          </Typography>
          <ProgressBar value={tableProps.row.original.cashFact || 0} />
        </div>
      ),
      Footer: (tableProps: CustomTableProps) => {
        const total = React.useMemo(() => {
          const rowsArray = Array(tableProps.rows).flat();
          const avarenge = rowsArray.reduce(
            (sum: any, row: any) => row.original.cashFact + sum,
            0
          );

          return roundNumbersToFixed(avarenge / rowsArray.length, 0) || 0;
        }, [tableProps.rows]);

        return (
          <div style={css.cashColumn.fact.footerWrapper}>
            <Typography
              sx={tableCss.tableCellText(theme)}
              align="center"
              className="with-right-border whiteText tableFooterText"
            >
              {total}
              <Percent theme={theme} />
            </Typography>
            <ProgressBar value={total} />
          </div>
        );
      },
    },
  ];
};

export { getCashColumns };

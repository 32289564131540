import { Grid, Theme } from "@mui/material";
import React, { useMemo } from "react";
import {
  Financing,
  IndicatorsRisks,
  Population,
  Structure,
} from "SRC/components/Cards";
import { useStats } from "SRC/redux/slices/main/hooks/useStats";
import {
  IFinancingData,
  IPopulationData,
  IStructureElements,
} from "SRC/types/analitics";

const widthControl = (theme: Theme, order: number, hard = false) => ({
  minWidth: "calc(50% - 3rem)",
  // [theme.breakpoints.between("lg", 5600)]: {
  //   minWidth: "calc(100% - 3rem)",
  // },
  [theme.breakpoints.up("xl")]: {
    minWidth: 0,
    flex: hard ? "0 0 auto" : 1,
    order,
  },
});

export const Analitics = (): JSX.Element => {
  const { items: stats } = useStats();
  const data = useMemo(() => stats[0]?.data || {}, [stats]);

  const structureData = useMemo<IStructureElements>(
    () => ({
      departmentProjects: {
        plan: data["Выполнение мероприятий ВП, план"],
        fact: data["Выполнение мероприятий ВП, факт"],
        total: data["Ведомственных проектов, кол-во"],
      },
      federalProjects: {
        plan: data["Выполнение мероприятий ФП, план"],
        fact: data["Выполнение мероприятий ФП, факт"],
        total: data["Федеральных проектов, кол-во"],
      },
      complexesProcessMeasures: {
        plan: data["Выполнение мероприятий КПМ, план"],
        fact: data["Выполнение мероприятий КПМ, факт"],
        total: data["Комплексы процессных мероприятий, кол-во"],
      },
      totalProjects: {
        plan:
          data["Выполнение мероприятий ВП, план"] +
          data["Выполнение мероприятий ФП, план"] +
          data["Выполнение мероприятий КПМ, план"],
        fact:
          data["Выполнение мероприятий ВП, факт"] +
          data["Выполнение мероприятий ФП, факт"] +
          data["Выполнение мероприятий КПМ, факт"],
        total: data["Структурные элементы, всего"],
      },
    }),
    [data]
  );

  const financingData = useMemo<IFinancingData>(
    () => ({
      extrabudgetary: {
        plan: data["Финансирование внебюджет план"],
        fact: data["Финансирование внебюджет факт"],
      },
      budget: {
        plan: data["Финансирование бюджет план"],
        fact: data["Финансирование бюджет факт"],
      },
      totalFinancing: {
        plan: data["Финансирование план"],
        fact: data["Финансирование факт"],
      },
    }),
    [data]
  );

  const populationData = useMemo<IPopulationData>(
    () => ({
      positive: data["Опросы населения, да"],
      negative: data["Опросы населения, нет"],
      percent:
        (data["Опросы населения, да"] / data["Опросы населения, нет"]) * 100,
    }),
    [data]
  );

  return (
    <Grid
      container
      gap={"1.5rem"}
      sx={(theme) => ({
        [theme.breakpoints.down("xl")]: {
          maxWidth: theme.breakpoints.values.md,
          margin: "auto",
        },
        [theme.breakpoints.up("xl")]: {
          flexWrap: "nowrap",
        },
      })}
    >
      <Grid sx={(theme) => widthControl(theme, 2, true)} item xs>
        <Structure data={structureData} />
      </Grid>
      <Grid sx={(theme) => widthControl(theme, 1)} item xs>
        <IndicatorsRisks />
      </Grid>
      <Grid sx={(theme) => widthControl(theme, 3)} item xs>
        <Financing data={financingData} />
      </Grid>
      <Grid sx={(theme) => widthControl(theme, 4)} item xs>
        <Population data={populationData} />
      </Grid>
    </Grid>
  );
};

import { Grid } from "@mui/material";
import React, { useMemo } from "react";
import { IndicatorLineGraph } from "SRC/components/Cards/IndicatorsRisks/common/IndicatorLineGraph/IndicatorLineGraph";
import { IndicatorLineGraphWrap } from "SRC/components/Cards/IndicatorsRisks/common/IndicatorLineGraph/IndicatorLineGraph.styled";
import { CustomTabs } from "SRC/components/CustomTabs/CustomTabs";
import { useStats } from "SRC/redux/slices/main/hooks/useStats";
import { IIndicatorRisksData } from "SRC/types/analitics";

import { CardWrapDiv } from "../../../CardWrap.styled";
import { indicatorTabStyles } from "./IndicatorsTabs.styled";

interface IProps {
  activeTab: number;
  setActiveTab: (value: number | ((prevVar: number) => number)) => void;
}

export const IndicatorsTabs = (props: IProps) => {
  const { items: stats } = useStats();
  const { activeTab, setActiveTab } = props;

  const data = useMemo(() => stats[0]?.data || {}, [stats]);

  const { objects, events, indicatiorRegulations, indicatorRegulationsAtRisk } =
    useMemo<IIndicatorRisksData>(
      () => ({
        objects: {
          plan: data["Создано объектов, план"],
          fact: data["Создано объектов, факт"],
          risks: data["Объектов под риском"],
          percent: data["Создано объектов, %"],
        },
        events: {
          plan: data["Мероприятий всего"],
          fact: data["Мероприятий под риском"],
          risks: data["Мероприятий под риском"],
          percent: data["Мероприятия под риском, %"],
        },
        indicatiorRegulations: {
          plan: data["Нормативно-правовые акты, план"],
          fact: data["Нормативно-правовые акты, факт"],
          percent: data["Нормативно правовые акты, %"],
          risks: data["Нормативно правовые акты, %"],
        },
        indicatorRegulationsAtRisk: {
          plan: data["Нормативно-правовые акты, план"],
          fact: data["Нормативно правовые акты, под риском"],
          percent: data["Нормативно правовые акты, %"],
          risks: data["Нормативно правовые акты, под риском"], // TODO НПА под риском
        },
      }),
      [data]
    );

  return (
    <CardWrapDiv>
      <Grid
        wrap={"nowrap"}
        justifyContent={"space-between"}
        gap="0.5rem"
        height={"100%"}
        container
      >
        <Grid flexGrow={"1"} item>
          <CustomTabs
            isSmall={true}
            value={activeTab}
            onChange={(event, newValue) => {
              setActiveTab(newValue);
            }}
            tabs={[
              {
                value: 0,
                label: "Индикаторы",
                sx: indicatorTabStyles,
              },
              {
                value: 1,
                label: "Риски",
                sx: indicatorTabStyles,
              },
            ]}
            components={[
              <IndicatorLineGraphWrap>
                <IndicatorLineGraph
                  colorLine={"success"}
                  done={objects.fact}
                  totalItems={objects.plan}
                  percent={objects.percent}
                  warning={objects.risks}
                  description="Создано объектов"
                />
                <IndicatorLineGraph
                  colorLine={"success"}
                  done={indicatiorRegulations.fact}
                  totalItems={indicatiorRegulations.plan}
                  percent={indicatiorRegulations.percent}
                  description="Нормативно-правовые акты"
                />
              </IndicatorLineGraphWrap>,
              <IndicatorLineGraphWrap>
                <IndicatorLineGraph
                  colorLine={"error"}
                  done={events.fact}
                  totalItems={events.plan}
                  percent={events.percent}
                  description="Мероприятия"
                />
                <IndicatorLineGraph
                  colorLine={"error"}
                  done={indicatorRegulationsAtRisk.risks}
                  totalItems={indicatorRegulationsAtRisk.plan}
                  percent={indicatorRegulationsAtRisk.percent}
                  description="Нормативно-правовые акты"
                />
              </IndicatorLineGraphWrap>,
            ]}
          />
        </Grid>
      </Grid>
    </CardWrapDiv>
  );
};

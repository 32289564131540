import { useCallback } from "react";
import { IStatParams } from "SRC/redux/slices/main/slices/stats";

import { useGoals } from "./useGoals";
import { usePremiers } from "./usePremiers";
import { usePrograms } from "./usePrograms";
import { useStats } from "./useStats";

export interface IMainState {
  loading: boolean;
  loaded: boolean;
  init(params: IStatParams): void;
  hasError: any;
}

export const useMain = (): IMainState => {
  const {
    fetching: loadingGoals,
    load: loadGoals,
    fetched: loadedGoals,
    error: errorGoals,
  } = useGoals();
  const {
    fetching: loadingPremiers,
    load: loadPremiers,
    fetched: loadedPremiers,
    error: errorPremiers,
  } = usePremiers();
  const {
    fetching: loadingPrograms,
    load: loadPrograms,
    fetched: loadedPrograms,
    error: errorProgramms,
  } = usePrograms();
  const {
    fetching: loadingStats,
    load: loadStats,
    fetched: loadedStats,
    error: errorStats,
  } = useStats();

  const loading =
    loadingGoals || loadingPremiers || loadingPrograms || loadingStats;
  const loaded = loadedGoals && loadedPremiers && loadedPrograms && loadedStats;
  const hasError = errorProgramms || errorGoals || errorPremiers || errorStats;
  const init = useCallback(
    (params) => {
      const { period } = params;
      if (!loading) {
        loadGoals(period);
        loadPremiers(period);
        loadPrograms(period);
        loadStats(params);
      }
    },
    [loading, loadGoals, loadPremiers, loadPrograms, loadStats]
  );

  return {
    loading,
    loaded,
    init,
    hasError,
  };
};

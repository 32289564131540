import { Theme } from "@mui/material";

export const selectorMixin = (theme: Theme) => ({
  optionStyle: {
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.primary.main,
    },
  },
});

export const css = {
  selector: selectorMixin,
  wrapper: (scrolled: boolean) => ({
    display: "flex",
    justifyContent: "space-between",
    padding: "0.5rem 4.5rem",
    gap: "1rem",
    backgroundColor: (theme: Theme) => theme.palette.background.default,
    borderBottom: (theme: Theme) =>
      `2px solid ${
        scrolled
          ? theme.palette.complementary.darkBlueTransparent
          : "transparent"
      }`,
  }),
  selectors: {
    display: "flex",
    flex: 1,
    gap: "1rem",
  },
  label: {
    fontSize: "0.8rem",
    lineHeight: "2em",
    color: "text.secondary",
  },
  indicator: {
    flex: "0 0 auto",
    display: "flex",
    flexDirection: "column",
  },
};

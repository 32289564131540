import { Theme } from "@mui/material";

export const getOption = (success: number, theme: Theme) => ({
  grid: {
    borderWidth: 0,
    width: "100%",
    height: "100%",
    left: "0",
    bottom: "0",
  },
  tooltip: {
    show: false,
  },
  series: [
    {
      type: "pie",
      radius: ["85%", "95%"],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.background.default,
        borderWidth: 5,
      },
      label: {
        show: false,
        position: "center",
      },
      emphasis: {
        scale: false,
      },
      labelLine: {
        show: false,
      },
      emptyCircleStyle: true,
      data: [
        {
          value: success,
          itemStyle: {
            color: theme.palette.success.main,
          },
        },
        {
          value: 100 - success,
          itemStyle: { color: theme.palette.error.main },
        },
      ],
    },
  ],
});

import { Theme } from "@mui/material";

export const css = {
  uiProgressBarStyle: (value: number, progressColor?: string) => ({
    backgroundColor: (theme: Theme) => theme.palette.background.default,
    borderRadius: 10,
    margin: "auto",
    maxWidth: "100%",
    width: "100%",
  }),
  progressLine: (value: number, progressColor?: string) => ({
    height: 8,
    width: `${value}%`,
    borderRadius: 4,
    backgroundColor: (theme: Theme) =>
      progressColor
        ? progressColor
        : value && value === 100
        ? theme.palette.success.main
        : theme.palette.primary.contrastText,
  }),
};

import { useCallback } from "react";
import { useProgramTopBest } from "SRC/redux/slices/gosprogram/hooks/useProgramTopBest";
import { useProgramTopWorst } from "SRC/redux/slices/gosprogram/hooks/useProgramTopWorst";
export interface ISummaryState {
  loading: boolean;
  loaded: boolean;
  init(): void;
  hasError: any;
}

export const useSummary = (): ISummaryState => {
  const {
    fetching: loadingProgramTopBest,
    load: loadProgramTopBest,
    fetched: loadedProgramTopBest,
    error: errorProgramTopBest,
  } = useProgramTopBest();
  const {
    fetching: loadingProgramTopWorst,
    load: loadProgramTopWorst,
    fetched: loadedProgramTopWorst,
    error: errorProgramTopWorst,
  } = useProgramTopWorst();

  const loading = loadingProgramTopBest || loadingProgramTopWorst;
  const loaded = loadedProgramTopBest || loadedProgramTopWorst;
  const hasError = errorProgramTopBest || errorProgramTopWorst;
  const init = useCallback(() => {
    if (!loading) {
      loadProgramTopBest();
      loadProgramTopWorst();
    }
  }, [loading, loadProgramTopBest, loadProgramTopWorst]);

  return {
    loading,
    loaded,
    init,
    hasError,
  };
};

import { Box, useTheme } from "@mui/material";
import React, { useLayoutEffect, useMemo, useState } from "react";
import ProgramsCounter from "SRC/components/ProgramsCounter/ProgramsCounter";
import { IOption } from "SRC/components/SelectWithSearch/SelectWithSearch";
import SelectWithSearchGP from "SRC/components/SelectWithSearch/SelectWithSearchGP";
import SelectWithSearchVP from "SRC/components/SelectWithSearch/SelectWithSearchVP";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import {
  IPremiers,
  usePremiers,
} from "SRC/redux/slices/main/hooks/usePremiers";
import {
  IPrograms,
  usePrograms,
} from "SRC/redux/slices/main/hooks/usePrograms";
import themeConfig from "SRC/theme";

import { css } from "./MainHeaderFilter.styled";

const MainHeaderFilter = () => {
  const theme = useTheme<typeof themeConfig>();

  const [scrolled, setScrolled] = useState<boolean>(false);
  const {
    program,
    vp,
    setSelectedProgram,
    setSelectedVP,
    filteredPrograms,
    filteredVPs,
  }: IFilters = useFilters();

  const { items: vpItems }: IPremiers = usePremiers();
  const { items: prItems }: IPrograms = usePrograms();

  const premiers: IOption[] = useMemo(
    () =>
      vpItems
        .filter((item) => filteredVPs.includes(item.VP_CODE))
        .map((item) => ({
          value: item.VP_CODE,
          label: item.VP_SHORT_NAME,
        })),
    [vpItems, filteredVPs]
  );

  const programs: IOption[] = useMemo(
    () =>
      prItems
        .filter((program) => filteredPrograms.includes(program.gp_code))
        .map((item) => ({
          value: item.gp_code,
          label: `${item.gp_code} ${item.gp_short_name}`,
        })),
    [prItems, filteredPrograms]
  );

  const onScroll = () => {
    setScrolled(Boolean(window.scrollY));
  };

  useLayoutEffect(() => {
    window.addEventListener("scroll", onScroll);
    onScroll();
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const selectStyle = useMemo(() => css.selector(theme), [theme]);

  return (
    <Box sx={css.wrapper(scrolled)}>
      <Box sx={css.selectors}>
        <SelectWithSearchVP
          value={vp}
          label="Вице-премьеры"
          style={selectStyle}
          onChange={setSelectedVP}
          options={premiers}
        />
        <SelectWithSearchGP
          value={program}
          label="Госпрограммы"
          style={selectStyle}
          onChange={setSelectedProgram}
          options={programs}
        />
      </Box>
      <Box sx={css.indicator}>
        <Box sx={css.label}>Кол-во госпрограмм</Box>
        <ProgramsCounter />
      </Box>
    </Box>
  );
};

export default MainHeaderFilter;

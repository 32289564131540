import { Box, SxProps, Theme, Typography } from "@mui/material";
import { SecretProgram, WarningTriangle } from "ASSETS/svg";
import warningTriangle from "ASSETS/svg/warningTriangle.svg";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";
import {
  WarningIconContainer,
  WarningIconImage,
} from "SRC/components/Table/assets/icons";
import {
  css,
  css as tableCss,
  TableCellBigText,
  TableFooterText,
} from "SRC/components/Table/BaseTable/BaseTable.styled";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>;
}

const getGovProgramsColumns = (theme: Theme) => {
  return [
    {
      Header: "№",
      accessor: "id",
      Cell: (tableProps: CustomTableProps) => (
        <>
          <Box sx={css.idCell}>
            <Box sx={css.iconContainer}>
              {tableProps.row.original.secretProgram === 1 && (
                <Box sx={css.secretProgram}>
                  <SecretProgram />
                </Box>
              )}
              {tableProps.row.original.atRisk > 0 && (
                <Box>
                  <WarningTriangle
                    style={{
                      width: "1.5rem",
                      height: "1.5rem",
                    }}
                  />
                </Box>
              )}
            </Box>

            <Typography
              sx={css.tableCellText}
              align="center"
              className="grayText"
            >
              {tableProps.row.original.id}
            </Typography>
          </Box>
        </>
      ),
      Footer: " ",
    },
    {
      Header: " ",
      accessor: "icon",
      Cell: (tableProps: CustomTableProps) => (
        <Box sx={css.flexCenter}>
          <img
            style={css.tableImage(true)}
            src={tableProps.row.original.icon}
            alt="govProgram"
          />
        </Box>
      ),
      Footer: " ",
    },
    {
      Header: "Госпрограмма",
      accessor: "govProgram",
      Cell: (tableProps: CustomTableProps) => (
        <Typography
          sx={css.tableCellText}
          className="with-right-border withMarginLeft whiteText"
        >
          {tableProps.row.original.govProgram}
        </Typography>
      ),
      Footer: (
        <Typography
          sx={css.tableCellText}
          className="with-right-border tableFooterText footerTextGray"
          align="left"
        >
          Итого
        </Typography>
      ),
    },
    {
      Header: "Показатели",
      accessor: "indicators",
      Cell: (tableProps: CustomTableProps) => (
        <Typography
          align="center"
          sx={css.tableCellText(theme)}
          className="bigText with-right-border grayText"
        >
          {tableProps.row.original.indicators}
        </Typography>
      ),
      Footer: (tableProps: CustomTableProps) => {
        const rowsArray = Array(tableProps.rows).flat();
        const total = React.useMemo(
          () =>
            rowsArray.reduce(
              (sum: any, row: any) => row.original.indicators + sum,
              0
            ),
          [rowsArray]
        );

        return (
          <Typography
            sx={css.tableCellText}
            className="with-right-border tableFooterText grayText"
          >
            {total}
          </Typography>
        );
      },
    },
    {
      Header: "Мероприятия",
      Footer: " ",
      columns: [
        {
          Header: "План",
          accessor: "total",
          Cell: (tableProps: CustomTableProps) => (
            <Typography
              align="center"
              sx={css.tableCellText(theme)}
              className="bigText with-dotted-border grayText"
            >
              {tableProps.row.original.plan}
            </Typography>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const rowsArray = Array(tableProps.rows).flat();
            const plan = React.useMemo(
              () =>
                rowsArray.reduce(
                  (sum: any, row: any) => row.original.plan + sum,
                  0
                ),
              [rowsArray]
            );

            return (
              <Typography
                sx={css.tableCellText}
                className="with-dotted-border tableFooterText grayText"
              >
                {plan}
              </Typography>
            );
          },
        },
        {
          Header: "Факт",
          accessor: "fact",
          Cell: (tableProps: CustomTableProps) => (
            <TableCellBigText
              align="center"
              sx={css.tableCellText(theme)}
              className="bigText with-dotted-border grayText"
            >
              {tableProps.row.original.fact}
            </TableCellBigText>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const rowsArray = Array(tableProps.rows).flat();
            const fact = React.useMemo(
              () =>
                rowsArray.reduce(
                  (sum: any, row: any) => row.original.fact + sum,
                  0
                ),
              [rowsArray]
            );

            return (
              <Typography
                sx={css.tableCellText}
                className="with-dotted-border tableFooterText grayText"
              >
                {fact}
              </Typography>
            );
          },
        },
        {
          Header: "Под риском",
          accessor: "atRisk",
          Cell: (tableProps: CustomTableProps) => (
            <Typography
              align="center"
              sx={tableCss.tableCellText(
                theme,
                "",
                false,
                tableProps.row.original.atRisk
              )}
              className="bigText with-right-border atRisk"
            >
              {tableProps.row.original.atRisk}
            </Typography>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const rowsArray = Array(tableProps.rows).flat();
            const total = React.useMemo(
              () =>
                rowsArray.reduce(
                  (sum: any, row: any) => row.original.atRisk + sum,
                  0
                ),
              [rowsArray]
            );

            return (
              <Typography
                align="center"
                sx={tableCss.tableCellText(theme, "", false, total)}
                className="tableFooterText with-right-border atRisk"
              >
                {total}
              </Typography>
            );
          },
        },
      ],
    },
    {
      Header: "Уровень достижения",
      accessor: "achievementLevel",
      Cell: (tableProps: CustomTableProps) => (
        <div style={{ padding: "20px 30px" }}>
          <TableCellBigText
            sx={{
              color: theme.palette.main.gray,
              textAlign: "center",
              mb: 2,
            }}
          >
            {`${roundNumbersToFixed(
              tableProps.row.original.achievementLevel,
              0
            )}%`}
          </TableCellBigText>
          <ProgressBar value={tableProps.row.original.achievementLevel || 0} />
        </div>
      ),
      Footer: (tableProps: CustomTableProps) => {
        const rowsArray = Array(tableProps.rows).flat();
        const total = React.useMemo(
          () =>
            rowsArray.reduce(
              (sum: any, row: any) => row.original.achievementLevel + sum,
              0
            ),
          [rowsArray]
        );

        const average = roundNumbersToFixed(total / rowsArray.length, 0) || 0;

        return (
          <div style={{ padding: "5px 30px" }}>
            <TableFooterText
              sx={{
                color: theme.palette.main.gray,
                textAlign: "center",
                mb: 2,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              {`${average}%`}
            </TableFooterText>
            <ProgressBar value={average} />
          </div>
        );
      },
    },
  ];
};

export { getGovProgramsColumns };

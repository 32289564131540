import { SxProps } from "@mui/material";
import React from "react";
import SelectWithSearch from "SRC/components/SelectWithSearch/SelectWithSearch";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import {
  IPrograms,
  usePrograms,
} from "SRC/redux/slices/main/hooks/usePrograms";

type TOptionValue = string | number;

export interface IOption {
  value: TOptionValue;
  label: string;
  disabled?: boolean;
}

interface IStyle {
  labelStyle?: SxProps;
  selectStyle?: SxProps;
  optionStyle?: SxProps;
}

interface ISelect {
  value?: string;
  onChange(value: TOptionValue): void;
  options: IOption[];
  hasAllOption?: boolean;
  label?: string;
  staticLabel?: boolean;
  colorizeActiveOption?: boolean;
  style: IStyle;
}

const SelectWithSearchGP = ({
  value,
  onChange,
  options,
  staticLabel = true,
  label,
  hasAllOption = true,
  colorizeActiveOption = true,
  style,
}: ISelect) => {
  const { program }: IFilters = useFilters();
  const { items: prItems }: IPrograms = usePrograms();

  const customOptions = [
    {
      value: "",
      label: "Все",
    },
    ...options,
  ];

  const validValue = program
    ? {
        value: prItems.filter((el) => el.gp_code === program)[0]?.gp_code,
        label:
          prItems.filter((el) => el.gp_code === program)[0]?.gp_code +
          " " +
          prItems.filter((el) => el.gp_code === program)[0]?.gp_short_name,
      }
    : {
        value: "",
        label: "Все",
      };

  return (
    <SelectWithSearch
      onChange={onChange}
      customOptions={customOptions}
      selectValue={validValue}
      getOptionLabel={(option: IOption) => option.label ?? option}
      staticLabel={staticLabel}
      label={label}
      colorizeActiveOption={colorizeActiveOption}
      style={style}
      hasCloseIcon={Boolean(value)}
      value={value}
    />
  );
};

export default SelectWithSearchGP;

import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { MutableRefObject, useMemo } from "react";
import { Column, useGlobalFilter, useSortBy, useTable } from "react-table";
import { TableModal } from "SRC/components/FullPageTableModal/FullPageTableModal";
import { BaseTable } from "SRC/components/Table";
import { TableActionsButtons } from "SRC/components/TableActionsButtons/TableActionsButtons";
import useTableExportParams from "SRC/hooks/useTableExportParams";
import { css } from "SRC/pages/Summary/common/SummaryComplexGrid/SummaryComplexGrid.styled";
import {
  IGeneralInfoPremiersDataItem,
  IGeneralInfoPremiersDataItemWithoutId,
} from "SRC/pages/Summary/common/SummaryComplexGrid/util/interfaces";

import { getGeneralInfoPremiersColumns } from "./tableData";

interface IGeneralInfoPremiersTab {
  posTable?: string;
  forwardRef?: MutableRefObject<HTMLElement | undefined>;
  onScroll?: (scroll: any) => void;
  tableData: IGeneralInfoPremiersDataItem[];
  vp: string;
  setSelectedVP: (code: string) => void;
  isTableModalOpen?: boolean;
  setTableModalOpen?: (value: boolean) => void;
  downloadData: IGeneralInfoPremiersDataItemWithoutId[];
  infoRows: Record<string, string>[][];
}

export const GeneralFullInfoPremiersTab = ({
  posTable,
  forwardRef,
  onScroll,
  tableData,
  vp,
  setSelectedVP,
  isTableModalOpen,
  setTableModalOpen,
  downloadData,
  infoRows,
}: IGeneralInfoPremiersTab) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const columns: Column[] = useMemo(
    () => getGeneralInfoPremiersColumns(theme),
    [theme]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    setSortBy,
  } = useTable(
    {
      columns,
      data: tableData,
    },
    useGlobalFilter,
    useSortBy
  );

  const { headings, mergeCells, wscols } = useTableExportParams(
    downloadData,
    "summaryVicePremiers"
  );

  const RenderTable = () => {
    return (
      <>
        {isTableModalOpen && (
          <Box sx={{ marginBottom: "1rem" }}>
            <TableActionsButtons
              open={isTableModalOpen}
              setOpen={setTableModalOpen}
              downloadData={downloadData}
              fileName="Сводная таблица. Вице-премьеры"
              wscols={wscols}
              headings={headings}
              mergeCells={mergeCells}
              infoRows={infoRows}
            />
          </Box>
        )}
        <Box sx={css.tablesWrapper(true)}>
          <BaseTable
            columns={columns}
            data={tableData}
            sortedColumns={[
              "Кол-во госпрограмм",
              "Уровень достижения",
              "Удовлетворенность населения",
            ]}
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            footerGroups={footerGroups}
            rows={rows}
            prepareRow={prepareRow}
            setSortBy={setSortBy}
            tableHeight={matches ? css.tableHeightLong : css.tableHeight}
            selectedRow={vp}
            setSelectedRow={setSelectedVP}
            withBigRows={!matches}
            wrapSort={matches}
            positionTable={posTable}
            forwardRef={forwardRef}
            onScroll={onScroll}
            scrollX={true}
          />
        </Box>
      </>
    );
  };

  return (
    <>
      <RenderTable />
      {!matches && (
        <TableModal
          open={isTableModalOpen}
          setOpen={setTableModalOpen}
          tableName="Сводная таблица. Вице-премьеры"
        >
          <RenderTable />
        </TableModal>
      )}
    </>
  );
};

import {
  Box,
  Grid,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import EventChart from "SRC/components/Charts/EventChart/EventChart";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { useCashExecution } from "SRC/redux/slices/gosprogram/hooks/useCashExecution";
import themeConfig from "SRC/theme";

import { css } from "./CashTab.styled";

interface ICashExecutionItem {
  NAME: string;
  SHORT_NAME: string;
  SORT_ORDER: string;
  code: string;
  data: any[];
}

interface ICashItem {
  month: string;
  plan: any;
  fact: any;
}

export const CashTab = () => {
  const theme = useTheme<typeof themeConfig>();
  const [cashTabData, setCashTabData] = useState<any>([]);
  const [cashTabMetaData, setCashTabMetaData] = useState<any>([]);
  const [unit, setUnit] = useState<string>("млрд");

  const { items: cashExecution } = useCashExecution();
  const { data: data1 } = cashExecution[0] || {};

  useEffect(() => {
    if (data1 && data1.length > 0) {
      const { data: data2 } = data1[0] || {};
      const { data: data3 } = data2[0] || {};
      setCashTabData(data3);
      setCashTabMetaData(data2);
    }
  }, [data1, unit]);

  const emptyArray = new Array(12).fill(0);

  const sortedCash = useMemo<ICashItem[]>(
    () =>
      cashTabData?.map((item: ICashExecutionItem) => ({
        month: item.code,
        plan:
          unit === "млрд"
            ? Number(item.data.find((el) => el.code === "1442").sum || 0)
            : unit === "млн"
            ? Number(item.data.find((el) => el.code === "1442").sum || 0) * 1000
            : Number(item.data.find((el) => el.code === "1442").sum || 0) *
              1000000,
        fact:
          unit === "млрд"
            ? Number(item.data.find((el) => el.code === "1443").sum || 0)
            : unit === "млн"
            ? Number(item.data.find((el) => el.code === "1443").sum || 0) * 1000
            : Number(item.data.find((el) => el.code === "1443").sum || 0) *
              1000000,
      })),
    [unit, cashExecution, cashTabData]
  );

  const planData = useMemo<number[]>(
    () => sortedCash?.map((item) => item.plan),
    [sortedCash]
  );

  // const today = moment().month();

  const factData = useMemo<number[]>(
    () => sortedCash?.map((item) => item.fact),
    [sortedCash]
  );

  const achievementLevels = useMemo(
    () =>
      cashTabData?.map((item: ICashExecutionItem) =>
        Number(item.data.find((el) => el.code === "1677")?.sum || 0)
      ),
    [cashExecution, cashTabData]
  );
  const displayAchievementLevel =
    achievementLevels && achievementLevels.length > 0
      ? Number(roundNumbersToFixed(Math.max(...achievementLevels), 1))
      : 0;

  return (
    <Box sx={css.cashTabWrapper}>
      <Grid container sx={css.cashTabContainer} justifyContent="space-between">
        <Grid item xs={1.5}>
          <Box sx={css.achievementContainer}>
            <Typography sx={css.countText}>
              {displayAchievementLevel}%
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box sx={css.divider} />
        </Grid>

        <Grid item xs={3}>
          <Typography sx={css.mainText}>Кассовое исполнение</Typography>
        </Grid>

        <Grid item xs={3}>
          <Box sx={css.divider} />
        </Grid>

        <Grid item xs={1.5}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Select
              value={unit}
              defaultValue="млдр"
              onChange={(event: SelectChangeEvent) =>
                setUnit(event.target.value)
              }
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="млрд">млрд &#8381;</MenuItem>
              <MenuItem value="млн">млн &#8381;</MenuItem>
              <MenuItem value="тыс">тыс &#8381;</MenuItem>
            </Select>
          </Box>
        </Grid>
      </Grid>
      <EventChart
        planData={planData || emptyArray}
        factData={factData || emptyArray}
      />
    </Box>
  );
};

import { actions as goals } from "./slices/goals";
import { actions as premiers } from "./slices/premiers";
import { actions as programs } from "./slices/programs";
import { actions as stats } from "./slices/stats";

export interface IMainActions {
  programs: typeof programs;
  goals: typeof goals;
  premiers: typeof premiers;
  stats: typeof stats;
}

const actions: IMainActions = {
  programs,
  goals,
  premiers,
  stats,
};

export default actions;

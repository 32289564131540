import { combineReducers } from "@reduxjs/toolkit";

import filtersSlice, { IFiltersState } from "./slices/filters";
import goalsSlice, { IGoalsState } from "./slices/goals";
import premiersSlice, { IPremiersState } from "./slices/premiers";
import programsSlice, { IProgramsState } from "./slices/programs";
import statsSlice, { IStatsState } from "./slices/stats";

export interface IMainReducer {
  programs: IProgramsState;
  goals: IGoalsState;
  premiers: IPremiersState;
  stats: IStatsState;
  filters: IFiltersState;
}

const reducer = combineReducers<IMainReducer>({
  programs: programsSlice.reducer,
  goals: goalsSlice.reducer,
  premiers: premiersSlice.reducer,
  stats: statsSlice.reducer,
  filters: filtersSlice.reducer,
});

export default reducer;

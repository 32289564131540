import { Box, SxProps } from "@mui/material";
import { SecretProgram } from "ASSETS/svg";
import React from "react";
import { useNavigate } from "react-router";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import useSingleAndDoubleClick from "SRC/hooks/useSingleAndDoubleClick";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { TProgram } from "SRC/redux/slices/main/hooks/usePrograms";

import { css } from "./Tiles.styled";

interface ITilesItemElement extends TProgram {
  active: boolean;
  secretProgram: number;
  onClick(): void;
}

export default function TilesItemElement({
  gp_icon_url,
  gp_short_name,
  gp_code,
  active,
  secretProgram,
  onClick,
  data,
}: ITilesItemElement) {
  const navigate = useNavigate();
  const { calculationMethod }: IFilters = useFilters();

  const onDoubleClick = (): void => {
    if (active) navigate(`/program/${gp_code}`);
  };

  const value =
    calculationMethod === "effect"
      ? data.find((el) => el.code === "1753")?.sum || 0
      : data.find((el) => el.code === "1796")?.sum || 0;

  const click = useSingleAndDoubleClick(onClick, onDoubleClick);

  return (
    <Box sx={css.item(active)} onClick={click}>
      <Box sx={css.icon as SxProps}>
        <span>{gp_code}</span>
        <img src={gp_icon_url} aria-hidden alt="Title Item Image." />
      </Box>
      <Box sx={css.title(value, active)}>
        <Box sx={css.shortName}>{gp_short_name}</Box>
        <Box sx={css.secretContainer as SxProps}>
          {secretProgram ? <SecretProgram /> : null}
        </Box>
      </Box>
      <Box sx={css.indicator(value, active) as SxProps}>
        <span>%</span>
        <p>{(value && roundNumbersToFixed(value, 0)) || 0}</p>
      </Box>
    </Box>
  );
}

import {
  IGovProgramDataItemWithoutIcon,
  IVicePremiersDataItem,
} from "SRC/pages/Main/common/ComplexGrid/util/interfaces";
import { IEventItem } from "SRC/pages/Program/common/EventsSection/util/interfaces";
import {
  IGeneralInfoDataItemWithoutIcon,
  IGeneralInfoPremiersDataItemWithoutId,
} from "SRC/pages/Summary/common/SummaryComplexGrid/util/interfaces";

const getGovProgramsWscols = (
  data: IGovProgramDataItemWithoutIcon[],
  govProgramsHeadings: Record<string, string>[]
) => [
  {
    wch: Math.max(govProgramsHeadings[8].id.length),
  },
  {
    wch: Math.max(...data.map((item) => String(item.govProgram).length)),
  },
  {
    wch: Math.max(govProgramsHeadings[8].indicators.length),
  },
  {
    wch: Math.max(govProgramsHeadings[9].events.length),
  },
  {
    wch: Math.max(govProgramsHeadings[9].total.length),
  },
  {
    wch: Math.max(govProgramsHeadings[9].atRisk.length),
  },
  {
    wch: Math.max(govProgramsHeadings[8].achievementLevel.length),
  },
];

const getVicePremiersWscols = (
  data: IVicePremiersDataItem[],
  vicePremiersHeadings: Record<string, string>[]
) => [
  {
    wch: Math.max(...data.map((item) => String(item.vicePremierName).length)),
  },
  {
    wch: Math.max(...data.map((item) => String(item.vicePremierPhoto).length)),
  },
  {
    wch: Math.max(vicePremiersHeadings[8].numOfPrograms.length),
  },
  {
    wch: Math.max(vicePremiersHeadings[9].events.length),
  },
  {
    wch: Math.max(vicePremiersHeadings[9].plan.length),
  },
  {
    wch: Math.max(vicePremiersHeadings[9].fact.length),
  },
  {
    wch: Math.max(vicePremiersHeadings[8].achievementLevel.length),
  },
];

const getProgramEventsWscols = (
  data: IEventItem[],
  programEventsHeadings: Record<string, string>[]
) => [
  {
    wch: Math.max(...data.map((item) => String(item.id).length)),
  },
  {
    wch: Math.max(...data.map((item) => String(item.name).length)) / 7,
  },
  {
    wch: Math.max(...data.map((item) => String(item.units).length)),
  },
  {
    wch: Math.max(programEventsHeadings[9].plan.length) * 2,
  },
  {
    wch: Math.max(programEventsHeadings[9].fact.length),
  },
  {
    wch: Math.max(programEventsHeadings[9].done.length),
  },
  {
    wch: Math.max(programEventsHeadings[9].effect.length),
  },
  {
    wch: Math.max(...data.map((item) => String(item.status).length)),
  },
];

const getSummaryProgramsWscols = (
  data: IGeneralInfoDataItemWithoutIcon[],
  summaryProgramsHeadings: Record<string, string>[]
) => [
  {
    wch: Math.max(summaryProgramsHeadings[8].id.length),
  },
  {
    wch: Math.max(...data.map((item) => String(item.govProgram).length)),
  },
  {
    wch: Math.max(...data.map((item) => String(item.vicePremier).length)),
  },
  {
    wch: Math.max(...data.map((item) => String(item.foiv).length)),
  },
  {
    wch: Math.max(summaryProgramsHeadings[8].performance.length),
  },
  {
    wch: Math.max(summaryProgramsHeadings[8].populationSatisfaction.length),
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].cashPlan.length) * 2,
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].cashFact.length) * 2,
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].goalsPlan.length),
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].goalsDone.length),
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].goalsFail.length),
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].indicatorsPlan.length),
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].indicatorsDone.length),
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].indicatorsFail.length),
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].eventsPlan.length),
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].eventsDone.length),
  },
  {
    wch: Math.max(summaryProgramsHeadings[9].eventsFail.length),
  },
];

const getSummaryVicePremiersWscols = (
  data: IGeneralInfoPremiersDataItemWithoutId[],
  summaryVicePremiersHeadings: Record<string, string>[]
) => [
  {
    wch: Math.max(...data.map((item) => String(item.vicePremier).length)),
  },
  {
    wch: Math.max(...data.map((item) => String(item.vicePremierPhoto).length)),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[8].govPrograms.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[8].performance.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[8].populationSatisfaction.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].cashPlan.length) * 2,
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].cashFact.length) * 2,
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].goalsPlan.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].goalsDone.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].goalsFail.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].indicatorsPlan.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].indicatorsDone.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].indicatorsFail.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].eventsPlan.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].eventsDone.length),
  },
  {
    wch: Math.max(summaryVicePremiersHeadings[9].eventsFail.length),
  },
];

export {
  getGovProgramsWscols,
  getProgramEventsWscols,
  getSummaryProgramsWscols,
  getSummaryVicePremiersWscols,
  getVicePremiersWscols,
};

import { Box, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import DividedDoughnutChart from "SRC/components/Charts/ DividedDoughnut/ DividedDoughnutChart";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import themeConfig from "SRC/theme";
import { IFinancingData } from "SRC/types/analitics";

import { CardWrap } from "../CardWrap";
import {
  financingBottomCount,
  FinancingBox,
  financingBoxStyles,
  FinancingBoxTitle,
  FinancingFact,
  financingScreenStatistic,
  financingTopCount,
  financingWrapStatistic,
  wrapImg,
} from "./Financing.styled";

interface IFinanceVariant {
  title: string;
  fact: string;
  topCount: number;
  bottomCount: number;
}

interface IFinanceProps {
  data: IFinancingData;
}

export const Financing = ({
  data: { extrabudgetary, budget, totalFinancing },
}: IFinanceProps) => {
  const theme = useTheme<typeof themeConfig>();

  const FinancingVariant = ({
    title,
    fact,
    topCount,
    bottomCount,
  }: IFinanceVariant) => {
    const isRight = fact === "right";

    return (
      <FinancingBox sx={financingBoxStyles.wrapper(isRight)}>
        <FinancingBoxTitle>{title}</FinancingBoxTitle>
        <svg width={20} height={20}>
          <circle cx={5} cy={10} r={5} />
        </svg>
        <FinancingFact>Факт</FinancingFact>
        <Box>
          <Typography sx={financingTopCount} variant="h4" component="div">
            {String((topCount && roundNumbersToFixed(topCount, 2)) || 0)}
          </Typography>
          <Typography sx={financingBottomCount} variant="h5" component="div">
            {String((bottomCount && roundNumbersToFixed(bottomCount, 2)) || 0)}
          </Typography>
        </Box>
      </FinancingBox>
    );
  };

  return (
    <CardWrap headerLeft="Финансирование" headerRight="млрд руб.">
      <Grid
        wrap={"nowrap"}
        justifyContent={"space-between"}
        gap="0.5rem"
        container
      >
        <Grid item sx={wrapImg}>
          <DividedDoughnutChart
            values={totalFinancing}
            areas={[budget, extrabudgetary]}
            colors={[theme.palette.text.onAccent, theme.palette.main.blue]}
          />
        </Grid>
        <Grid flexGrow={"1"} item>
          <Grid flexWrap={"nowrap"} sx={financingWrapStatistic}>
            <Grid sx={financingScreenStatistic} flexWrap="nowrap" container>
              <FinancingVariant
                title={"Бюджет"}
                fact={"left"}
                topCount={budget.fact}
                bottomCount={budget.plan}
              />
              <FinancingVariant
                title={"Внебюджет"}
                fact={"right"}
                topCount={extrabudgetary.fact}
                bottomCount={extrabudgetary.plan}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardWrap>
  );
};

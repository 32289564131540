import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProgramEvents } from "SRC/redux/slices/gosprogram/api/client";

interface IProgramEventDataItem {
  sum?: number;
  code: string;
  text?: string;
  comment?: string;
}

export interface IProgramEvent {
  NAME: string;
  R_ID: string;
  code: string;
  data: IProgramEventDataItem[];
  eventsCode: string[];
  Документ: string;
  Нацпроект: string;
  "Тип мероприятия": string;
  "Документ/НацПроект": string;
  "Ед. измерения бюджета": string;
  "Срок начала реализации": string;
  "Ед. измерения результата": string;
  "Срок окончания реализации": string;
  "Ответственный исполнитель": string;
  "Характеристика результата": string;
}

export interface IProgramEventsState {
  items: IProgramEvent[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export interface IParams {
  id: string | number;
  fp_code: string | null;
  dp_code: string | null;
  cpm_code: string | null;
  period?: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "programEvents/get",
    async (params: IParams) => await getProgramEvents(params)
  ),
};

const initialState: IProgramEventsState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const isIProgramEvent = (array: any): array is IProgramEvent[] =>
  !!array?.length;

const slice = createSlice({
  name: "programEvents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: IProgramEventsState, action) => {
          state.items = isIProgramEvent(action.payload.data)
            ? action.payload.data
            : [];
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;

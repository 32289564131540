import { Box } from "@mui/material";
import warningTriangle from "ASSETS/svg/warningTriangle.svg";
import React, { CSSProperties, ReactNode } from "react";
import {
  MediumGrayText,
  SpaceBetweenContainer,
} from "SRC/pages/Program/common/GovProgramInfoWidget/GovProgramInfoWidget.styled";

import {
  InfoCardContainer,
  InfoCardDivider,
  InfoCardTitle,
} from "./InfoCard.styled";

interface IProps {
  title: string;
  units: string;
  atRisk?: number;
  style?: CSSProperties;
  children: ReactNode;
}

const InfoCard = (props: IProps) => {
  const { title, units, atRisk, style, children } = props;

  return (
    <InfoCardContainer style={style}>
      <Box>
        <SpaceBetweenContainer>
          <Box style={{ display: "flex", gap: "0.5rem" }}>
            <InfoCardTitle>{title}</InfoCardTitle>
            {Boolean(atRisk) && (
              <img
                src={warningTriangle}
                style={{ width: "1.5rem", height: "1.25rem" }}
                alt="at risk"
              />
            )}
          </Box>
          <MediumGrayText>{units}</MediumGrayText>
        </SpaceBetweenContainer>
        <InfoCardDivider />
      </Box>
      {children}
    </InfoCardContainer>
  );
};

export default InfoCard;

import { Box, Grid, Typography, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Column, useGlobalFilter, useSortBy, useTable } from "react-table";
import BaseTable from "SRC/components/Table/BaseTable/BaseTable";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { useSubjects } from "SRC/redux/slices/gosprogram/hooks/useSubjects";

import { css } from "./SubjectTab.styled";

interface ISubjectItem {
  NAME: string;
  REGION_CODE: string;
  URL: string;
  code: string;
  data: any[];
}

export interface ISubjectTableData {
  id: string;
  statusLabel: string;
  nameSubject: string;
  timePlan: string;
  timeFact: string;
  countObjectPlan: number;
  countObjectFact: number;
}

interface IProps {
  columns: Column[];
}

export const SubjectTab = ({ columns }: IProps) => {
  const theme = useTheme();

  const { items: subjects } = useSubjects();

  const { data: data1 } = subjects[0] || {};

  const [subjectsData, setSubjectsData] = useState<any>([]);
  const [filterValue, setFilterValue] = useState<string>("");

  useEffect(() => {
    if (data1 && data1.length > 0) {
      const { data: data2 } = data1[0] || {};
      const { data: data3 } = data2[0] || {};
      setSubjectsData(data3);
    }
  }, [data1]);

  const data = useMemo<ISubjectTableData[]>(
    () =>
      subjectsData?.map((item: ISubjectItem) => ({
        id: item.code,
        statusLabel: "completed",
        nameSubject: item.NAME,
        timePlan: item.data.find((el) => el.code === "1423").text,
        timeFact: item.data.find((el) => el.code === "1424").text,
        countObjectPlan: roundNumbersToFixed(
          item.data.find((el) => el.code === "1421").sum
            ? item.data.find((el) => el.code === "1421").sum
            : Number(item.data.find((el) => el.code === "1421").text),
          0
        ),
        countObjectFact: roundNumbersToFixed(
          item.data.find((el) => el.code === "1422").sum,
          0
        ),
      })),
    [subjects, subjectsData]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );

  const totalItems = data && data.length > 0 ? 55 : 0;
  const doneItems = data && data.length > 0 ? 48 : 0;
  const notDoneItems = data && data.length > 0 ? 5 : 0;
  const noDataItems = data && data.length > 0 ? 2 : 0;

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        padding: "0.5rem 2.5rem",
      }}
    >
      <Grid container sx={{ gap: "1rem", marginBottom: "0.5rem" }}>
        <Grid item>
          <Box
            onClick={() => setFilterValue("")}
            sx={css.flexContainer(filterValue === "")}
          >
            <span className="grayText">Всего </span>
            <span>{totalItems}</span>
          </Box>
        </Grid>
        <Grid item>
          <Box
            onClick={() => setFilterValue("done")}
            sx={css.flexContainer(filterValue === "done")}
          >
            <Box sx={css.styledDot} className="green" />
            <span className="grayText">Выполнено </span>
            <span>{doneItems}</span>
          </Box>
        </Grid>
        <Grid item>
          <Box
            onClick={() => setFilterValue("notDone")}
            sx={css.flexContainer(filterValue === "notDone")}
          >
            <Box sx={css.styledDot} className="red" />
            <span className="grayText">Не выполнено </span>
            <span>{notDoneItems}</span>
          </Box>
        </Grid>
        <Grid item>
          <Box
            onClick={() => setFilterValue("noData")}
            sx={css.flexContainer(filterValue === "noData")}
          >
            <Box sx={css.styledDot} className="blue" />
            <span className="grayText">Не представлена отчетность </span>
            <span>{noDataItems}</span>
          </Box>
        </Grid>
      </Grid>
      <BaseTable
        columns={columns}
        data={data}
        sortedColumns={["План", "Факт"]}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
        setSortBy={setSortBy}
        tableHeight={"31rem"}
      />
    </Box>
  );
};

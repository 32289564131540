import { Box, styled, Tab, Theme } from "@mui/material";
import { CSSProperties } from "react";

interface IProps {
  isSmall?: boolean;
  widthFull?: boolean;
  withBgr?: boolean;
  borderColor?: string;
  borderType?: string;
  styles?: CSSProperties;
  filtersNode?: boolean;
}

export const TabTitle = styled(Tab)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "1.5rem",
  textTransform: "none",
  lineHeight: "1.5em",
  maxWidth: "100%",
  color: theme.palette.text.secondary,
}));

export const TabsContainer = styled(Box)<IProps>(
  ({ theme, isSmall, widthFull, styles }) => ({
    maxWidth: isSmall ? theme.breakpoints.values.md : "100%",
    width: "100%",
    "& .MuiTabs-root": {
      minHeight: isSmall ? "0px" : "",
      width: isSmall || widthFull ? "100%" : "",
      borderBottom: isSmall ? `3px dotted ${theme.palette.main.darkBlue}` : "",
      justifyContent: isSmall ? "space-between" : "",
    },
    '& .Mui-selected[aria-selected="true"]': {
      color: theme.palette.text.onAccent,
    },
    "& .MuiButtonBase-root": {
      fontWeight: "bold",
      fontSize: styles?.fontSize || (isSmall ? "0.8rem" : "2rem"), // не смог переопределить специфичность .MuiButtonBase-root
      lineHeight: "1.5em",
      minHeight: isSmall ? "0rem" : "",
      padding: isSmall ? "0.2rem 0.6rem 0.3rem 0.6rem" : "",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: (theme: Theme) => theme.palette.primary.main,
      borderRadius: "2px 2px 0 0",
      height: isSmall ? "0.15rem" : "0.25rem",
    },
  })
);

export const TabsTitlesContainer = styled(Box)<IProps>(
  ({
    theme,
    withBgr,
    isSmall,
    borderType,
    filtersNode,
  }: IProps & { theme: Theme }) => ({
    paddingBottom: 0,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: withBgr || !isSmall ? 0 : "1.7rem",
    },
    display: "flex",
    justifyContent: "space-between",
    backgroundImage:
      borderType === "solid"
        ? `linear-gradient(to right, ${theme.palette.background.opacityWhite} 100%, rgba(255,255,255,0) 0%)`
        : borderType !== "none"
        ? "linear-gradient(to right, #2E364A 33%, rgba(255,255,255,0) 0%)"
        : "none",
    backgroundPosition: "bottom",
    backgroundSize: "1rem 0.1rem",
    backgroundRepeat: "repeat-x",
    alignItems: "flex-end",
    height: filtersNode ? "4rem" : "auto",
  })
);

export const TabsWithBgrContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: "1rem",
  background: theme.palette.complementary.darkBlue,
  borderRadius: "0.85rem",
  padding: "0 1.5rem",
  width: "88%",
}));

export const RightNodeContainer = styled(TabsWithBgrContainer)(({ theme }) => ({
  width: "11%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0rem 1.5rem 0",
  height: "100%",
}));

export const TabWithNumContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "0.6rem",
}));

export const NumContainer = styled(Box)<IProps>(
  ({ theme, borderColor }: IProps & { theme: Theme }) => ({
    border: `2px solid ${borderColor || "transparent"}`,
    padding: "0px 1rem",
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    alignItems: "baseline",
    background: theme.palette.complementary.darkBlue,
    height: "2rem",
    fontWeight: "bold",
    fontSize: "1.5rem",
    lineHeight: "2rem",
  })
);

import { Box, Grid, SxProps, Typography } from "@mui/material";
import { StructureLineGraph } from "SRC/components/Cards/Structure/elements/StructureLineGraph/StructureLineGraph";
import { IStructureElements } from "SRC/types/analitics";

import { CardWrap } from "../CardWrap";
import {
  BlockTitleWrapper,
  innerMainWidget,
  innerSecondWidget,
  InnerWidgetTitle,
  TitleCount,
} from "./Structure.styled";

interface IBlockTitle {
  name: string;
  value: string | number;
  isTotal?: boolean;
}

interface IStructureProps {
  data: IStructureElements;
}

const BlockTitle = ({ name, value, isTotal = false }: IBlockTitle) => (
  <Grid
    item
    sx={(theme) => ({
      ...BlockTitleWrapper,
      borderBottom: `0.15rem dotted ${
        isTotal
          ? theme.palette.main.darkBlue
          : theme.palette.complementary.darkBlue
      }`,
    })}
  >
    <Typography sx={InnerWidgetTitle} component="div" color={"text.primary"}>
      {name}
    </Typography>
    <Typography sx={TitleCount} component="div" color={"text.onAccent"}>
      {value}
    </Typography>
  </Grid>
);

export const Structure = ({
  data: {
    totalProjects,
    departmentProjects,
    complexesProcessMeasures,
    federalProjects,
  },
}: IStructureProps) => {
  return (
    <CardWrap headerLeft="Структурные элементы" headerRight="ед.">
      <Grid
        container
        justifyContent={"space-between"}
        wrap={"nowrap"}
        gap="0.5rem"
      >
        <Box sx={innerMainWidget as SxProps}>
          <BlockTitle name="Всего" value={totalProjects.total} isTotal />
          <StructureLineGraph
            done={totalProjects.fact}
            totalItems={totalProjects.plan}
          />
        </Box>
        <Box sx={innerSecondWidget as SxProps}>
          <BlockTitle name="ФП" value={federalProjects.total} />
          <StructureLineGraph
            done={federalProjects.fact}
            totalItems={federalProjects.plan}
            typeLine="alt"
          />
        </Box>
        <Box sx={innerSecondWidget as SxProps}>
          <BlockTitle name="ВП" value={departmentProjects.total} />
          <StructureLineGraph
            done={departmentProjects.fact}
            totalItems={departmentProjects.plan}
            typeLine="alt"
          />
        </Box>
        <Box sx={innerSecondWidget as SxProps}>
          <BlockTitle name="КПМ" value={complexesProcessMeasures.total} />
          <StructureLineGraph
            done={complexesProcessMeasures.fact}
            totalItems={complexesProcessMeasures.plan}
            typeLine="alt"
          />
        </Box>
      </Grid>
    </CardWrap>
  );
};

import { Theme } from "@mui/material";

export const BlockTitleWrapper = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  flex: 1,
};

export const InnerWidgetTitle = () => ({
  fontSize: "1.125em",
  lineHeight: 1.2,
  fontWeight: 700,
});

export const TitleCount = (theme: Theme) => ({
  fontSize: "1.25em",
  fontWeight: "700",
  paddingBottom: "12px",
  [theme.breakpoints.up("md")]: {
    fontSize: "1.5em",
  },
});

const widgetCss = (theme: Theme) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  borderRadius: "0.5rem",
  padding: "1rem",
  [theme.breakpoints.only("md")]: {
    padding: "1.5rem",
  },
});

export const innerMainWidget = (theme: Theme) => ({
  ...widgetCss(theme),
  backgroundColor: theme.palette.complementary.darkBlue,
  flex: "0 0 30%",
  fontSize: "1rem",
  [theme.breakpoints.down("md")]: {
    flex: "0 0 20%",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.25rem",
  },
});

export const innerSecondWidget = (theme: Theme) => ({
  ...widgetCss(theme),
  backgroundColor: theme.palette.background.default,
  fontSize: "0.75rem",
  [theme.breakpoints.up("md")]: {
    fontSize: "1rem",
  },
});

export const bottomCount = (theme: Theme) => ({
  fontSize: "0.9rem",
  fontWeight: "bold",
  [theme.breakpoints.up("md")]: {
    fontSize: "1.1rem",
  },
});

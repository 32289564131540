import "react-datepicker/dist/react-datepicker.css";

import {
  Box,
  Container,
  FormControl,
  MenuItem,
  Select,
  SxProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import logo from "ASSETS/logo.png";
import { BurgerMenu } from "ASSETS/svg/index";
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Calendar } from "SRC/components/Calendar/Calendar";
import { MainHeaderFilter } from "SRC/components/FilterForms";
import { useAppSelector } from "SRC/redux/hooks";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { usePrograms } from "SRC/redux/slices/main/hooks/usePrograms";
import themeConfig from "SRC/theme";
import { MAIN, urlList } from "SRC/urls";

import { css, NavTab } from "./header.styled";

export const Header = (): JSX.Element => {
  const theme = useTheme<typeof themeConfig>();
  const match = useMediaQuery(theme.breakpoints.up("lg"));
  const isExtraLarge = useMediaQuery(theme.breakpoints.up("xl"));

  const { items } = usePrograms();
  const { program }: IFilters = useFilters();

  const { pathname } = useLocation();

  const headerText = useMemo(() => {
    if (program) {
      return items?.find((item) => item.gp_code === program)?.gp_short_name;
    }
    if (urlList.find((item) => item.link === pathname)) {
      return urlList.find((item) => item.link === pathname)?.title;
    }
    return "Паспорт Госпрограммы";
  }, [pathname]);

  const { user } = useAppSelector((state) => state.auth.user);

  const navs = useMemo(
    () =>
      urlList.map((route) => (
        <NavTab to={route.link} key={route.link + route.title}>
          {route.title}
        </NavTab>
      )),
    []
  );

  if (!user)
    return (
      <Box component="header" sx={css.wrapper}>
        <Box sx={css.content}>
          <Box sx={css.contentWrapper as SxProps}>
            {match && <Box sx={css.leftRoundedBorder as SxProps} />}
            <Container sx={css.containerSX}>
              <Box component="a" href="/" sx={css.logo}>
                <img src={logo} alt="Герб" />
                Госпрограммы
              </Box>
            </Container>
            {match && <Box sx={css.rightRoundedBorder as SxProps} />}
          </Box>
        </Box>
      </Box>
    );

  return (
    <Box component="header" sx={css.wrapper}>
      <Box sx={css.content}>
        <Box sx={css.contentWrapper as SxProps}>
          {match && <Box sx={css.leftRoundedBorder as SxProps} />}
          <Container sx={css.containerSX}>
            <Box component="a" href="/" sx={css.logo}>
              <img src={logo} alt="Герб" />
              Госпрограммы
            </Box>
            {isExtraLarge ? (
              <Box sx={css.largeNavsWrapper}>
                <Box sx={css.selectedSection}>{headerText}</Box>
                <Box sx={css.selectWrapper as SxProps}>
                  <FormControl fullWidth sx={css.formControl}>
                    <Box sx={css.menuIcon}>
                      <BurgerMenu />
                    </Box>
                    <Select renderValue={(value: any) => null} sx={css.select}>
                      {urlList.map((route) => (
                        <MenuItem
                          value={route.link + route.title}
                          key={route.link + route.title}
                          sx={css.menuItem}
                        >
                          <NavTab sx={css.navOnLarge} to={route.link}>
                            {route.title}
                          </NavTab>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            ) : (
              <nav>{navs}</nav>
            )}
            <Calendar />
          </Container>
          {match && <Box sx={css.rightRoundedBorder as SxProps} />}
        </Box>
      </Box>
      {!match && pathname === MAIN && <MainHeaderFilter />}
    </Box>
  );
};

import { Theme } from "@mui/material";

export const selectorMixin = (theme: Theme) => ({
  selectStyle: {
    "@media (minWidth:3800px)": {
      padding: "20px 24px 15px",
      fontSize: "1.4rem",
      height: "auto",
      minHeight: "40px",
      borderRadius: "20px",
    },
  },
  labelStyle: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.3rem",
    },
  },
  optionStyle: {
    "@media (minWidth:3800px)": {
      fontSize: "1.4rem",
    },
  },
});

const upLgMixin = (theme: Theme) => ({
  "@media (minWidth:3800px)": {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
  },
});

export const css = {
  tableHeight: "56rem",
  width: "max-content",
  tableHeightLong: "43rem",
  customTabsContainer: { fontSize: "1.5rem" },
  tablesWrapper: (scrollX: boolean) => ({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    minHeight: 0,
    overflowX: scrollX ? "scroll" : "",
    margin: scrollX ? "0 -1rem" : "",
  }),
  idColumn: {
    cellWrapper: { position: "relative", padding: "0 20px" },
    width: 50,
    tableCell: (theme: Theme) => ({
      color: theme.palette.text.onAccent,
      fontWeight: theme.typography.fontWeightRegular,
      p: 4,
      pr: 2,
      ...upLgMixin(theme),
    }),
  },
  iconColumn: {
    cellWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    image: { borderRadius: "50%" },
    width: 50,
  },
  nameColumn: {
    width: 500,
    headerWrapper: { mx: "1.25rem", textAlign: "left" },
    cellWrapper: (theme: Theme) => ({
      marginLeft: "1.25rem",
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.fontWeightRegular,
      [theme.breakpoints.up("lg")]: {
        fontWeight: theme.typography.fontWeightBold,
      },
    }),
    footerWrapper: (theme: Theme) => ({
      ml: 5,
      color: theme.palette.text.onAccent,
      [theme.breakpoints.up("lg")]: {
        fontSize: "2rem",
      },
    }),
  },
  govProgramsColumn: {
    footerWrapper: { padding: "5px 20px 0px 10px" },
  },
  performanceColumn: {
    cellWrapper: { padding: "20px 20px 20px 10px" },
    text: (theme: Theme, value: number) => ({
      color: value ? theme.palette.text.onAccent : theme.palette.main.gray,
      textAlign: "center",
      mb: 2,
    }),
    footerText: (theme: Theme, value: number) => ({
      color: value ? theme.palette.text.onAccent : theme.palette.main.gray,
      textAlign: "center",
      mb: 2,
      marginLeft: "auto",
      marginRight: "auto",
    }),
  },
  populationSatisfactionColumn: {
    cellWrapper: {
      padding: "20px 25px 20px 25px",
    },
    cellWrapperDashboard: {
      padding: "20px 25px 20px 25px",
    },
    cellText: (theme: Theme, value: number) => ({
      color: value ? theme.palette.text.onAccent : theme.palette.main.gray,
      textAlign: "center",
      mb: 2,
    }),
    footerWrapper: { padding: "5px 25px 5px 25px" },
    footerWrapperDashboard: { padding: "5px 25px 5px 25px" },
  },
  foivColumn: {
    cellText: (theme: Theme) => ({
      color: theme.palette.text.onAccent,
      fontWeight: theme.typography.fontWeightRegular,
      textAlign: "left",
      mb: 2,
      ...upLgMixin(theme),
    }),
  },
  vicePremierColumn: {
    cellText: (theme: Theme) => ({
      textAlign: "left",
      color: theme.palette.text.onAccent,
      fontWeight: theme.typography.fontWeightRegular,
      ...upLgMixin(theme),
    }),
  },
  eventsGoalsColumn: {
    width: 180,
  },
  eventsIndicatorsColumn: {
    width: 180,
  },
  cashColumn: {
    plan: {
      width: 200,
      cellWrapper: (theme: Theme, value: number) => ({
        color: value ? theme.palette.text.onAccent : theme.palette.main.gray,
      }),
      footerWrapper: (theme: Theme, value: number) => ({
        color: value ? theme.palette.text.onAccent : theme.palette.main.gray,
      }),
    },
    fact: {
      width: 200,
      cellWrapper: { padding: "20px 30px" },
      cellText: (theme: Theme) => ({
        color: theme.palette.text.onAccent,
      }),
      footerWrapper: { padding: "5px 30px" },
      footerText: (theme: Theme) => ({
        color: theme.palette.text.onAccent,
      }),
    },
  },
  populateColumn: {
    width: 300,
  },
  goalsColumn: {
    plan: {
      width: 200,
      footerWrapper: (theme: Theme) => ({ color: theme.palette.text.onAccent }),
    },
    done: {
      width: 200,
      cellWrapper: (theme: Theme) => ({
        color: theme.palette.success.main,
      }),
      footerWrapper: (theme: Theme) => ({
        color: theme.palette.success.main,
      }),
    },
    fail: {
      width: 200,
      cellWrapper: (theme: Theme) => ({
        color: theme.palette.error.main,
      }),
      footerWrapper: (theme: Theme) => ({
        color: theme.palette.error.main,
      }),
    },
  },
  tabsStyle: {
    backgroundImage:
      "linear-gradient(to right, #2E364A 33%, rgba(255,255,255,0) 0%)",
    backgroundPosition: "bottom",
    backgroundSize: "1rem 0.1rem",
    backgroundRepeat: "repeat-x",
    display: "flex",
    flex: "0 0 55%",
  },
  filtersContainer: (theme: Theme) => ({
    width: "60%",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flex: "0 0 45%",
    },
  }),
  tabsWrapper: (theme: Theme, tabsRight?: boolean, withFilters?: boolean) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: 1,
    color: theme.palette.main.gray,
    fontWeight: theme.typography.fontWeightRegular,
    width: "min-content",
    ml: tabsRight ? "auto" : "",
    padding: "0.25rem",
    minHeight: "2rem",
    height: withFilters ? "2.5rem" : "3rem",
    alignSelf: withFilters ? "end" : "auto",
    "& .MuiTabs-flexContainer": {
      alignItems: "center",
      height: "100%",
    },
    '& .Mui-selected[aria-selected="true"]': {
      color: theme.palette.text.onAccent,
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: theme.palette.main.blue,
      borderRadius: withFilters ? "4px" : "8px",
    },
    "& .MuiTab-root": {
      fontSize: withFilters ? "1rem" : "1.2rem",
      padding: "0.75rem 1.75rem",
      whiteSpace: "nowrap",
      minHeight: "auto",
      height: "100%",
      borderRight: "1px solid " + theme.palette.main.darkBlue,
      textTransform: "none",
      "&:last-child": {
        borderRight: "none",
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  }),
  activeTab: (theme: Theme) => ({
    backgroundColor: theme.palette.main.blue,
    borderRadius: 1,
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "1rem",
  }),
  tab: (theme: Theme) => ({
    backgroundColor: theme.palette.main.blue,
    borderRadius: 1,
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "1rem",
  }),
  tableContainer: {
    width: "100%",
    maxWidth: "1820px",
    "@media (min-width: 1820px)": {
      margin: "auto",
    },
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "8px",
    height: "84px",
  },
  selectorsWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  filters: (theme: Theme) => ({
    width: "50%",
    "@media (min-width:5000px)": {
      width: "60%",
    },
  }),
  tableHeaderWrapper: {
    display: "flex",
    justifyContent: "space-between",
    height: "96px",
    gap: "1rem",
    marginBottom: "1.5rem",
  },
  tableTitle: {
    flex: "1 0 80%",
    padding: "0.75rem 2rem",
    color: (theme: Theme) => theme.palette.text.onAccent,
  },
  divider: {
    backgroundImage:
      "linear-gradient(to right, #2E364A 33%, rgba(255,255,255,0) 0%)",
    backgroundPosition: "bottom",
    backgroundSize: "1rem 0.4rem",
    backgroundRepeat: "repeat-x",
    height: "7px",
    marginBottom: "0.7rem",
  },
  selector: selectorMixin,
  secretProgram: {
    "& svg": {
      width: "1.5rem",
      height: "1.5rem",
    },
  },
  iconContainer: {
    display: "flex",
    gap: "0.5rem",
    flexDirection: "column",
    position: "absolute",
    top: "50%",
    transform: "translateY(-43%)",
    left: "-0.6rem",
  },
  idCell: {
    padding: "0 20px",
    display: "flex",
    flexDirection: "column",
  },
};

import { Box, Grid, Typography } from "@mui/material";
import { ErrorPolygon, SuccessPolygon, WarningTriangle } from "ASSETS/svg";
import React, { FC } from "react";
import theme from "SRC/theme";

import {
  DoneLine,
  DoneLineCount,
  DoneLineIco,
  InProgressLine,
  LineCountLeft,
  LineCountRight,
  LineTrack,
  LineWrap,
  WarningCount,
  WarningIco,
  WarningLine,
} from "./IndicatorLineGraph.styled";

interface Props {
  description?: string;
  totalItems: number;
  done?: number;
  warning?: number;
  inProgress?: number;
  colorLine?: string;
  percent?: number;
}

export const IndicatorLineGraph: FC<Props> = ({
  description,
  done,
  totalItems,
  warning,
  inProgress,
  colorLine,
  percent,
}) => {
  return (
    <Grid
      container
      justifyContent={"space-between"}
      alignItems={"center"}
      flexWrap={"nowrap"}
    >
      {description && (
        <Box
          sx={{
            flex: "0 0 30%",
            [theme.breakpoints.up("xl")]: { width: "100%", flex: "0 0 40%" },
          }}
        >
          <Typography
            component="div"
            sx={{
              fontSize: "1.125rem",
              color: theme.palette.text.onAccent,
              fontWeight: "bold",
              [theme.breakpoints.only("xs")]: {
                fontSize: "1rem",
              },
            }}
          >
            {description}
          </Typography>
          <Typography
            component="div"
            sx={{
              fontSize: "1.125rem",
              fontWeight: 400,
              color: theme.palette.divider,
              [theme.breakpoints.only("xs")]: {
                fontSize: "1rem",
              },
            }}
          >
            ед.
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          flex: "0 0 65%",
          [theme.breakpoints.up("xl")]: { flex: "0 0 55%" },
        }}
      >
        <Grid
          justifyContent={"space-between"}
          alignItems={"end"}
          sx={{ my: 1 }}
          container
        >
          <LineCountLeft color={"text.onAccent"}>{done || 0}</LineCountLeft>
          <LineCountRight color={"text.secondary"}>
            {totalItems || 0}
          </LineCountRight>
        </Grid>
        <LineWrap sx={{ mb: 7 }}>
          <LineTrack>
            <DoneLine
              sx={{
                width: `${percent}%`,
                backgroundColor:
                  colorLine === "success"
                    ? theme.palette.success.main
                    : theme.palette.error.main,
                "&:after": {
                  backgroundColor:
                    colorLine === "success"
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                },
              }}
            >
              <DoneLineIco sx={{ width: "0.75rem", height: "0.5rem" }}>
                {colorLine === "success" ? (
                  <SuccessPolygon />
                ) : (
                  <ErrorPolygon />
                )}
              </DoneLineIco>
              <DoneLineCount>
                <Typography
                  sx={{ lineHeight: "1.7", fontSize: "1rem" }}
                  variant="h5"
                  component="div"
                  color={"text.onAccent"}
                >
                  {`${percent || 0}`}
                </Typography>
              </DoneLineCount>
            </DoneLine>
            {inProgress && <InProgressLine sx={{ width: `${percent}%` }} />}
            {warning != null && warning !== 0 && (
              <WarningLine sx={{ width: `${percent}%` }}>
                <WarningIco sx={{ width: "1.625rem", height: "1.375rem" }}>
                  <WarningTriangle />
                </WarningIco>

                <WarningCount>
                  <Typography
                    sx={{ lineHeight: "1.7", fontSize: "1rem" }}
                    variant="h5"
                    component="div"
                    color={"error.main"}
                  >
                    {warning}
                  </Typography>
                </WarningCount>
              </WarningLine>
            )}
          </LineTrack>
        </LineWrap>
      </Box>
    </Grid>
  );
};

import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { SecretProgram } from "ASSETS/svg";
import React from "react";
import { RadialPolarChart } from "SRC/components/Charts";
import MethodSwitcher from "SRC/components/MethodSwitcher/MethodSwitcher";
import FinancingCard from "SRC/pages/Program/common/GovProgramInfoWidget/InfoCard/FinancingCard/FinancingCard";
import InfoCard from "SRC/pages/Program/common/GovProgramInfoWidget/InfoCard/InfoCard";
import SimpleInfo from "SRC/pages/Program/common/GovProgramInfoWidget/InfoCard/SimpleInfo/SimpleInfo";
import StructureElementsCard from "SRC/pages/Program/common/GovProgramInfoWidget/InfoCard/StructureElementsCard/StructureElementsCard";
import themeConfig from "SRC/theme";

import {
  BigWhiteText,
  DottedLineDark,
  ProgramId,
  ProgramType,
} from "../GovProgramInfoWidget.styled";
import { css } from "./ProgramInfo.styled";

interface IDataItem {
  id: string | number;
  name: string;
  icon: string;
  programType: string;
  programSecret: number;
  chartData: {
    categories: string[];
    values: number[];
  };
  indicators: {
    total: number;
    done: number;
    units: string;
    percentage: number;
  };
  structureElements: {
    elements: {
      name: string;
      amount: number;
      total: number;
      done: number;
      percentage: number;
    }[];
    total: number;
    units: string;
    percentage: number;
  };
  financing: {
    fact: number;
    plan: number;
    budget: {
      total: number;
      done: number;
    };
    extrabudget: {
      total: number;
      done: number;
    };
    units: string;
  };
}

interface IProgramInfo {
  data: IDataItem;
}

export const ProgramInfo = ({ data }: IProgramInfo) => {
  const {
    id,
    name,
    programType,
    chartData,
    financing,
    indicators,
    structureElements,
  } = data;

  const theme = useTheme<typeof themeConfig>();
  const isExtraLarge = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <Grid container sx={css.programInfoContainer}>
      <Grid item sx={css.leftHeaderContainer}>
        <Box sx={{ width: "100%" }}>
          {!isExtraLarge && <ProgramId>{id}</ProgramId>}
          <BigWhiteText style={{ lineHeight: "2.75rem", textAlign: "left" }}>
            {name}
          </BigWhiteText>
          <ProgramType>{programType}</ProgramType>
          <DottedLineDark />
        </Box>
        {data.programSecret && isExtraLarge ? (
          <Box sx={css.secretProgram}>
            <SecretProgram />
          </Box>
        ) : null}
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <MethodSwitcher />
        </Grid>
        <Grid item xs={6} md={8} lg={6} style={{ display: "flex" }}>
          <Box sx={css.chartContainer}>
            <RadialPolarChart
              categories={chartData.categories}
              values={chartData.values}
            />
          </Box>
        </Grid>
        <Grid item xs={6} md={4} lg={6}>
          <Grid container direction="column" gap={"1rem"}>
            <Grid item>
              <InfoCard title="Показатели" units={indicators.units}>
                <SimpleInfo
                  doneOf={indicators.done}
                  totalOf={indicators.total}
                  percentage={indicators.percentage}
                />
              </InfoCard>
            </Grid>
            <Grid item>
              <InfoCard
                title="Структурные элементы"
                units={structureElements.units}
              >
                <StructureElementsCard
                  elements={structureElements?.elements}
                  total={structureElements?.total}
                  percentage={structureElements?.percentage}
                />
              </InfoCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ marginTop: "3rem" }}>
        <InfoCard title="Финансирование" units={financing.units}>
          <FinancingCard financing={financing} />
        </InfoCard>
      </Grid>
    </Grid>
  );
};

import React, { FC, useState } from "react";
import { CustomTabs } from "SRC/components/CustomTabs/CustomTabs";
import { CashTab } from "SRC/pages/Program/common/EventCard/EventTabs/CashTab/CashTab";
import { CharacterTab } from "SRC/pages/Program/common/EventCard/EventTabs/CharacterTab/CharacterTab";
import { ObjectTabWrapper } from "SRC/pages/Program/common/EventCard/EventTabs/ObjectTab/ObjectTabWrapper";
import { RelatedIndicators } from "SRC/pages/Program/common/EventCard/EventTabs/RelatedIndicators/RelatedIndicators";
import { SubjectTab } from "SRC/pages/Program/common/EventCard/EventTabs/SubjectTab/SubjectTab";
import { subjectColumns } from "SRC/pages/Program/common/EventCard/EventTabs/SubjectTab/util/subjectTableData";

import { css } from "./EventTabs.styled";

export const EventTabs: FC = () => {
  const [activeTab, setActiveTab] = useState(0);

  const components = [
    <CashTab />,
    <CharacterTab />,
    <SubjectTab columns={subjectColumns} />,
    <ObjectTabWrapper />,
    <RelatedIndicators columns={subjectColumns} />,
  ];
  const tabs = [
    {
      value: 0,
      label: "Кассовое исполнение",
    },
    {
      value: 1,
      label: "Характеристика результата",
    },
    {
      value: 2,
      label: "Реализация в субъектах",
    },
    {
      value: 3,
      label: "Перечень объектов",
    },
    {
      value: 4,
      label: "Связанные показатели",
    },
  ];

  return (
    <CustomTabs
      value={activeTab}
      onChange={(event, newValue) => {
        setActiveTab(newValue);
      }}
      tabs={tabs}
      components={components}
      style={css.tabs}
      stylePanel={css.tabPanel}
      widthFull={true}
      borderType={"solid"}
    />
  );
};

import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { IPeriod } from "SRC/redux/slices/main/slices/filters";
import { IPremier } from "SRC/redux/slices/main/slices/premiers";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../actions";

export type TPremier = IPremier;

export interface IPremiers {
  fetching: boolean;
  fetched: boolean;
  items: TPremier[];
  load(period: IPeriod): void;
  getItemByCode(code: string): TPremier | undefined;
  error: SerializedError | null;
}

export const usePremiers = (): IPremiers => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items: premiers = [],
    fetched,
  } = useAppSelector((state) => state.main.premiers);
  const [error, setError] = useState<SerializedError | null>(null);

  const load = useCallback(
    (params) => {
      if (!fetching) {
        dispatch(actions.premiers.get(params)).then((action) => {
          if (actions.premiers.get.rejected.match(action)) {
            setError(action.error);
          }
          return action;
        });
      }
    },
    [dispatch, fetching]
  );

  const getItemByCode = (code: string): TPremier | undefined =>
    premiers.find((premier: TPremier) => premier.VP_CODE === code);

  return {
    fetching,
    fetched,
    items: premiers,
    getItemByCode,
    load,
    error,
  };
};

import { Theme } from "@mui/material";

export const css = {
  wrapper: (isYearView: boolean, isMonthView: boolean) => ({
    padding: "1rem 1.5rem 1rem",
    background: (theme: Theme) => theme.palette.background.paper,
    borderRadius: "8px",
    boxShadow: (theme: Theme) =>
      `0px 2px 10px 2px ${theme.palette.complementary.lightShadow}`,
    border: (theme: Theme) =>
      `1px solid ${theme.palette.complementary.darkGrayBlue}`,
    color: (theme: Theme) => theme.palette.primary.contrastText,
    width: "20rem",
    "& .react-datepicker": {
      backgroundColor: (theme: Theme) => theme.palette.background.paper,
      color: (theme: Theme) => theme.palette.primary.contrastText,
      border: "none",
      width: "100%",
    },
    "& .react-datepicker__header": {
      display: isYearView ? "none" : "flex",
      backgroundColor: "transparent",
      borderBottom: "none",
    },
    "& .react-datepicker__year-wrapper, .react-datepicker__month-wrapper": {
      maxWidth: "unset",
      justifyContent: "center",
    },
    "& .react-datepicker__month-wrapper": {
      display: "flex",
      textTransform: "capitalize",
    },
    "& .react-datepicker__quarter-wrapper": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    "& .react-datepicker__year-text, .react-datepicker__month-text, .react-datepicker__quarter-text":
      {
        width: isMonthView ? "5.4rem" : "3.4rem",
        height: "3.4rem",
        display: "flex !important",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "1rem",
        "&:hover": {
          borderRadius: "8px",
          background: (theme: Theme) => theme.palette.charts.blueAreaOpacity,
        },
      },
    "& .react-datepicker__quarter-text": {
      width: "6rem !important",
    },
    "& .react-datepicker__year-text--selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__month--selected, .react-datepicker__quarter--selected":
      {
        background: (theme: Theme) => theme.palette.charts.blueAreaOpacity,
        border: (theme: Theme) => `1px solid ${theme.palette.primary.main}`,
        borderRadius: "8px",
        fontSize: "1.125rem",
        "&:hover": {
          backgroundColor: (theme: Theme) =>
            `${theme.palette.primary.main} !important`,
        },
      },
    "& .react-datepicker__month-container": {
      float: "unset",
    },
  }),
  calendarContainer: {
    position: "relative",
  },
  buttonsContainer: {
    width: "100%",
    display: "flex",
    background: (theme: Theme) => theme.palette.complementary.darkGrayBlue,
    border: (theme: Theme) => `1px solid ${theme.palette.text.secondary}`,
    borderRadius: "8px",
    padding: "4px",
    justifyContent: "space-between",
  },
  button: (isActive: boolean) => ({
    flex: "0 0 32%",
    color: (theme: Theme) =>
      isActive ? theme.palette.primary.contrastText : theme.palette.main.gray,
    fontSize: "1rem",
    fontWeight: isActive ? "bold" : "normal",
    background: (theme: Theme) =>
      isActive ? theme.palette.primary.main : "transparent",
    borderRadius: "2px",
    textAlign: "center",
    lineHeight: "0.9rem",
    padding: "5px",
    cursor: "pointer",
  }),
  datePickerContainer: {
    "& .react-datepicker-wrapper": {
      display: "flex",
      width: "100%",
    },
  },
  datePickerHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    width: "100%",
  },
  headerBtn: {
    cursor: "pointer",
    width: "5rem",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  headerText: {
    fontSize: "1.25rem",
    lineHeight: "2rem",
    fontWeight: "bold",
    color: (theme: Theme) => theme.palette.primary.contrastText,
  },
  textField: (theme: Theme) => ({
    backgroundColor: theme?.palette.complementary.darkRed,
    borderRadius: "0.5rem",
    overflow: "hidden",
    "& .MuiInputBase-input": {
      padding: "0.625rem",
      color: theme.palette.text.onAccent,
      fontSize: "1rem",
    },
    "& .MuiButtonBase-root svg": {
      fill: theme.palette.text.onAccent,
      opacity: 0.5,
      width: "1.25rem",
      height: "1.125rem",
    },
    "& .MuiFilledInput-root": {
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .Mui-focused": {
      border: `2px solid ${theme.palette.primary.contrastText}`,
      borderRadius: "8px",
    },
  }),
  arrowContainer: {
    width: "1rem",
    height: "2.5rem",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    cursor: "pointer",
  },
};

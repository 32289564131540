import { Box, SxProps } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IDataGoal } from "SRC/pages/Program/common/NationalGoalsConnect/NationalGoalsConnect";
import { useAppSelector } from "SRC/redux/hooks";
import { useProgramFilters } from "SRC/redux/slices/gosprogram/hooks/useProgramFilters";
import theme from "SRC/theme";

import { css } from "./GoalItem.styled";

interface IGoalItemProps {
  item: IDataGoal;
  activeFilter: boolean;
  filterGoal: boolean;
  id: number;
}

interface IResultGoals {
  factors: number[];
  programId: any[];
}

export const GoalItem = ({
  item,
  activeFilter,
  filterGoal,
  id,
}: IGoalItemProps) => {
  const [activeFactor, setActiveFactor] = useState(false);
  const [activeGoal, setActiveGoal] = useState(false);
  const [activeProgram, setActiveProgram] = useState(false);
  const [programCount, setProgramCount] = useState<number>(0);
  const {
    selectedProgramGoalFactorsId,
    selectedGoalId,
    selectedHiddenFactorsId,
  } = useAppSelector((state) => state.gosprogram.programFilters);
  const {
    setSelectedProgramGoalFactorsId,
    setSelectedGoalId,
    setSelectedGoalProgramId,
    setSelectedGoalFactorsId,
    setSelectedProgramGoalId,
    setSelectedProgramGoalCodes,
    setSelectedHiddenFactorsId,
    setRemoveHiddenFactorsId,
  } = useProgramFilters();

  const handleActiveGoal = () => {
    setSelectedProgramGoalId("");
    setSelectedProgramGoalFactorsId([]);
    setSelectedProgramGoalCodes([]);
    const result = getGoalsInfo(item);
    if (!activeGoal) {
      selectedGoalId === item.id
        ? setSelectedGoalId("")
        : setSelectedGoalId(item.id);
      setSelectedGoalFactorsId(result.factors);
      setSelectedGoalProgramId(result.programId);
    } else {
      setSelectedGoalId("");
      setSelectedGoalFactorsId([]);
      setSelectedGoalProgramId([]);
      setSelectedHiddenFactorsId("");
    }
  };

  const handleFilterFactor = (idFactor: number) => {
    if (!selectedHiddenFactorsId.includes(idFactor)) {
      setSelectedHiddenFactorsId(idFactor);
    } else {
      setRemoveHiddenFactorsId(idFactor);
    }
  };

  const getGoalsInfo = (item: IDataGoal) => {
    const resultGoals: IResultGoals = {
      factors: [],
      programId: [],
    };
    item.factors.map((factor) => {
      resultGoals.factors.push(factor.idFactor);
      if (factor.programId !== null) {
        resultGoals.programId.push(factor.programId);
      } else {
        resultGoals.programId.push([]);
      }
    });
    return resultGoals;
  };

  useEffect(() => {
    let count = 0;
    item.factors.map((factor) => {
      if (factor.programId !== null) {
        count = count + factor.programId.length;
      }
    });
    setProgramCount(count);
  }, []);

  useEffect(() => {
    if (selectedProgramGoalFactorsId.length) {
      setActiveProgram(true);
    } else {
      setActiveProgram(false);
    }
  }, [selectedProgramGoalFactorsId]);
  useEffect(() => {
    if (selectedGoalId === item.id) {
      setActiveGoal(true);
      setActiveFactor(true);
    } else {
      setActiveGoal(false);
      setActiveFactor(false);
    }
  }, [selectedGoalId, selectedHiddenFactorsId]);

  return (
    <Box
      sx={css.itemRight(activeGoal, activeProgram)}
      id={`goalItem-${id}`}
      className="goalItem"
    >
      <Box sx={css.programCounter(theme, activeGoal, activeProgram)}>
        <Box sx={css.counter(theme, activeGoal, activeProgram)}>
          {programCount}
        </Box>
      </Box>
      <Box sx={css.factorContainer} className={"factorContainer"}>
        <Box sx={css.borderFactor(theme, activeGoal, filterGoal)}>
          <Box sx={css.borderRound}></Box>
        </Box>
        <Box sx={css.factorsList}>
          {item.factors.map((factor, i) => (
            <Box
              sx={css.factor(
                activeGoal,
                !selectedProgramGoalFactorsId.length && activeGoal
                  ? !selectedHiddenFactorsId.includes(factor.idFactor)
                  : selectedProgramGoalFactorsId.includes(factor.idFactor),
                theme
              )}
              onClick={() => handleFilterFactor(factor.idFactor)}
            >
              <Box sx={css.factorPoint}>{factor.pointFactor}</Box>
              <Box sx={css.factorName}>{factor.nameFactor}</Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={css.itemRightContainer} onClick={handleActiveGoal}>
        <Box sx={css.rightImg}>
          <img src={item.icon} />
        </Box>
        <Box
          sx={css.rightName(
            activeGoal,
            activeFactor,
            activeFilter,
            filterGoal,
            theme
          )}
        >
          {item.goalTitle}
        </Box>
      </Box>
    </Box>
  );
};

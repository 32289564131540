import {
  getGovProgramsWscols,
  getProgramEventsWscols,
  getSummaryProgramsWscols,
  getSummaryVicePremiersWscols,
  getVicePremiersWscols,
} from "SRC/helpers/getTableWscols";
import {
  IGovProgramDataItemWithoutIcon,
  IVicePremiersDataItem,
} from "SRC/pages/Main/common/ComplexGrid/util/interfaces";
import { IEventItem } from "SRC/pages/Program/common/EventsSection/util/interfaces";
import {
  IGeneralInfoDataItemWithoutIcon,
  IGeneralInfoPremiersDataItemWithoutId,
} from "SRC/pages/Summary/common/SummaryComplexGrid/util/interfaces";
import { Range } from "xlsx";

const govProgramsHeadings = [
  {
    id: " ",
    govProgram: " ",
    indicators: " ",
    events: " ",
    total: " ",
    atRisk: " ",
    achievementLevel: " ",
  },
  {
    id: " ",
    govProgram: " ",
    indicators: " ",
    events: " ",
    total: " ",
    atRisk: " ",
    achievementLevel: " ",
  },
  {
    id: " ",
    govProgram: " ",
    indicators: " ",
    events: " ",
    total: " ",
    atRisk: " ",
    achievementLevel: " ",
  },
  {
    id: " ",
    govProgram: " ",
    indicators: " ",
    events: " ",
    total: " ",
    atRisk: " ",
    achievementLevel: " ",
  },
  {
    id: " ",
    govProgram: " ",
    indicators: " ",
    events: " ",
    total: " ",
    atRisk: " ",
    achievementLevel: " ",
  },
  {
    id: " ",
    govProgram: " ",
    indicators: " ",
    events: " ",
    total: " ",
    atRisk: " ",
    achievementLevel: " ",
  },
  {
    id: " ",
    govProgram: " ",
    indicators: " ",
    events: " ",
    total: " ",
    atRisk: " ",
    achievementLevel: " ",
  },
  {
    id: " ",
    govProgram: " ",
    indicators: " ",
    events: " ",
    total: " ",
    atRisk: " ",
    achievementLevel: " ",
  },
  {
    id: "Код ГП",
    govProgram: "Госпрограмма",
    indicators: "Показатели",
    events: "Мероприятия",
    total: " ",
    atRisk: "",
    achievementLevel: "Уровень достижения",
  },
  {
    id: " ",
    govProgram: " ",
    indicators: " ",
    events: "План",
    total: "Факт",
    atRisk: "Под риском",
    achievementLevel: " ",
  },
];

const govProgramsMergeCells = [
  { s: { r: 0, c: 2 }, e: { r: 0, c: 18 } },
  { s: { r: 1, c: 2 }, e: { r: 1, c: 18 } },
  { s: { r: 2, c: 2 }, e: { r: 2, c: 18 } },
  { s: { r: 3, c: 2 }, e: { r: 3, c: 18 } },
  { s: { r: 4, c: 2 }, e: { r: 4, c: 18 } },
  { s: { r: 5, c: 2 }, e: { r: 5, c: 18 } },
  { s: { r: 6, c: 2 }, e: { r: 6, c: 18 } },
  { s: { r: 8, c: 0 }, e: { r: 9, c: 0 } },
  { s: { r: 8, c: 1 }, e: { r: 9, c: 1 } },
  { s: { r: 8, c: 2 }, e: { r: 9, c: 2 } },
  { s: { r: 8, c: 3 }, e: { r: 8, c: 5 } },
  { s: { r: 8, c: 6 }, e: { r: 9, c: 6 } },
];

const vicePremiersHeadings = [
  {
    vicePremierName: " ",
    vicePremierPhoto: " ",
    numOfPrograms: " ",
    events: " ",
    plan: " ",
    fact: " ",
    achievementLevel: " ",
  },
  {
    vicePremierName: " ",
    vicePremierPhoto: " ",
    numOfPrograms: " ",
    events: " ",
    plan: " ",
    fact: " ",
    achievementLevel: " ",
  },
  {
    vicePremierName: " ",
    vicePremierPhoto: " ",
    numOfPrograms: " ",
    events: " ",
    plan: " ",
    fact: " ",
    achievementLevel: " ",
  },
  {
    vicePremierName: " ",
    vicePremierPhoto: " ",
    numOfPrograms: " ",
    events: " ",
    plan: " ",
    fact: " ",
    achievementLevel: " ",
  },
  {
    vicePremierName: " ",
    vicePremierPhoto: " ",
    numOfPrograms: " ",
    events: " ",
    plan: " ",
    fact: " ",
    achievementLevel: " ",
  },
  {
    vicePremierName: " ",
    vicePremierPhoto: " ",
    numOfPrograms: " ",
    events: " ",
    plan: " ",
    fact: " ",
    achievementLevel: " ",
  },
  {
    vicePremierName: " ",
    vicePremierPhoto: " ",
    numOfPrograms: " ",
    events: " ",
    plan: " ",
    fact: " ",
    achievementLevel: " ",
  },
  {
    vicePremierName: " ",
    vicePremierPhoto: " ",
    numOfPrograms: " ",
    events: " ",
    plan: " ",
    fact: " ",
    achievementLevel: " ",
  },
  {
    vicePremierName: "Вице-премьер",
    vicePremierPhoto: "Фото",
    numOfPrograms: "Госпрограммы",
    events: "Мероприятия",
    plan: " ",
    fact: " ",
    achievementLevel: "Уровень достижения",
  },
  {
    vicePremierName: " ",
    vicePremierPhoto: " ",
    numOfPrograms: " ",
    events: "План",
    plan: "Факт",
    fact: "Под риском",
    achievementLevel: " ",
  },
];
const vicePremiersMergeCells = [
  { s: { r: 0, c: 2 }, e: { r: 0, c: 18 } },
  { s: { r: 1, c: 2 }, e: { r: 1, c: 18 } },
  { s: { r: 2, c: 2 }, e: { r: 2, c: 18 } },
  { s: { r: 3, c: 2 }, e: { r: 3, c: 18 } },
  { s: { r: 4, c: 2 }, e: { r: 4, c: 18 } },
  { s: { r: 5, c: 2 }, e: { r: 5, c: 18 } },
  { s: { r: 6, c: 2 }, e: { r: 6, c: 18 } },
  { s: { r: 8, c: 0 }, e: { r: 9, c: 0 } },
  { s: { r: 8, c: 1 }, e: { r: 9, c: 1 } },
  { s: { r: 8, c: 2 }, e: { r: 9, c: 2 } },
  { s: { r: 8, c: 3 }, e: { r: 8, c: 5 } },
  { s: { r: 8, c: 6 }, e: { r: 9, c: 6 } },
];

const programEventsHeadings = [
  {
    id: " ",
    name: " ",
    units: " ",
    plan: " ",
    fact: " ",
    done: " ",
    effect: " ",
    status: " ",
  },
  {
    id: " ",
    name: " ",
    units: " ",
    plan: " ",
    fact: " ",
    done: " ",
    effect: " ",
    status: " ",
  },
  {
    id: " ",
    name: " ",
    units: " ",
    plan: " ",
    fact: " ",
    done: " ",
    effect: " ",
    status: " ",
  },
  {
    id: " ",
    name: " ",
    units: " ",
    plan: " ",
    fact: " ",
    done: " ",
    effect: " ",
    status: " ",
  },
  {
    id: " ",
    name: " ",
    units: " ",
    plan: " ",
    fact: " ",
    done: " ",
    effect: " ",
    status: " ",
  },
  {
    id: " ",
    name: " ",
    units: " ",
    plan: " ",
    fact: " ",
    done: " ",
    effect: " ",
    status: " ",
  },
  {
    id: " ",
    name: " ",
    units: " ",
    plan: " ",
    fact: " ",
    done: " ",
    effect: " ",
    status: " ",
  },
  {
    id: " ",
    name: " ",
    units: " ",
    plan: " ",
    fact: " ",
    done: " ",
    effect: " ",
    status: " ",
  },
  {
    id: " ",
    name: " ",
    units: " ",
    plan: " ",
    fact: " ",
    done: " ",
    effect: " ",
    status: " ",
  },
  {
    id: "ID",
    name: "Наименование",
    units: "Ед. изм.",
    plan: "План",
    fact: "Факт",
    done: "Выполнение",
    effect: "Влияние",
    status: "Статус",
  },
];

const programEventsMergeCells = [
  { s: { r: 0, c: 2 }, e: { r: 0, c: 18 } },
  { s: { r: 1, c: 2 }, e: { r: 1, c: 18 } },
  { s: { r: 2, c: 2 }, e: { r: 2, c: 18 } },
  { s: { r: 3, c: 2 }, e: { r: 3, c: 18 } },
  { s: { r: 4, c: 2 }, e: { r: 4, c: 18 } },
  { s: { r: 5, c: 2 }, e: { r: 5, c: 18 } },
  { s: { r: 6, c: 2 }, e: { r: 6, c: 18 } },
];

const summaryProgramsHeadings = [
  {
    id: " ",
    govProgram: " ",
    vicePremier: " ",
    foiv: " ",
    performance: " ",
    populationSatisfaction: " ",
    cashPlan: " ",
    cashFact: " ",
    goalsPlan: " ",
    goalsDone: " ",
    goalsFail: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    indicatorsFail: " ",
    eventsPlan: " ",
    eventsDone: " ",
    eventsFail: " ",
  },
  {
    id: " ",
    govProgram: " ",
    vicePremier: " ",
    foiv: " ",
    performance: " ",
    populationSatisfaction: " ",
    cashPlan: " ",
    cashFact: " ",
    goalsPlan: " ",
    goalsDone: " ",
    goalsFail: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    indicatorsFail: " ",
    eventsPlan: " ",
    eventsDone: " ",
    eventsFail: " ",
  },
  {
    id: " ",
    govProgram: " ",
    vicePremier: " ",
    foiv: " ",
    performance: " ",
    populationSatisfaction: " ",
    cashPlan: " ",
    cashFact: " ",
    goalsPlan: " ",
    goalsDone: " ",
    goalsFail: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    indicatorsFail: " ",
    eventsPlan: " ",
    eventsDone: " ",
    eventsFail: " ",
  },
  {
    id: " ",
    govProgram: " ",
    vicePremier: " ",
    foiv: " ",
    performance: " ",
    populationSatisfaction: " ",
    cashPlan: " ",
    cashFact: " ",
    goalsPlan: " ",
    goalsDone: " ",
    goalsFail: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    indicatorsFail: " ",
    eventsPlan: " ",
    eventsDone: " ",
    eventsFail: " ",
  },
  {
    id: " ",
    govProgram: " ",
    vicePremier: " ",
    foiv: " ",
    performance: " ",
    populationSatisfaction: " ",
    cashPlan: " ",
    cashFact: " ",
    goalsPlan: " ",
    goalsDone: " ",
    goalsFail: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    indicatorsFail: " ",
    eventsPlan: " ",
    eventsDone: " ",
    eventsFail: " ",
  },
  {
    id: " ",
    govProgram: " ",
    vicePremier: " ",
    foiv: " ",
    performance: " ",
    populationSatisfaction: " ",
    cashPlan: " ",
    cashFact: " ",
    goalsPlan: " ",
    goalsDone: " ",
    goalsFail: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    indicatorsFail: " ",
    eventsPlan: " ",
    eventsDone: " ",
    eventsFail: " ",
  },
  {
    id: " ",
    govProgram: " ",
    vicePremier: " ",
    foiv: " ",
    performance: " ",
    populationSatisfaction: " ",
    cashPlan: " ",
    cashFact: " ",
    goalsPlan: " ",
    goalsDone: " ",
    goalsFail: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    indicatorsFail: " ",
    eventsPlan: " ",
    eventsDone: " ",
    eventsFail: " ",
  },
  {
    id: " ",
    govProgram: " ",
    vicePremier: " ",
    foiv: " ",
    performance: " ",
    populationSatisfaction: " ",
    cashPlan: " ",
    cashFact: " ",
    goalsPlan: " ",
    goalsDone: " ",
    goalsFail: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    indicatorsFail: " ",
    eventsPlan: " ",
    eventsDone: " ",
    eventsFail: " ",
  },
  {
    id: "Код ГП",
    govProgram: "Госпрограмма",
    vicePremier: "Вице-премьер",
    foiv: "Министерства",
    performance: "Уровень достижения",
    populationSatisfaction: "Удовлетворенность населения",
    cashPlan: "Кассовое исполнение",
    cashFact: " ",
    goalsPlan: "Цели",
    goalsDone: " ",
    goalsFail: " ",
    indicatorsPlan: "Показатели",
    indicatorsDone: " ",
    indicatorsFail: " ",
    eventsPlan: "Мероприятия",
    eventsDone: " ",
    eventsFail: " ",
  },
  {
    id: " ",
    govProgram: " ",
    vicePremier: " ",
    foiv: " ",
    performance: " ",
    populationSatisfaction: " ",
    cashPlan: "План",
    cashFact: "Факт",
    goalsPlan: "План",
    goalsDone: "Факт",
    goalsFail: "Не выполнено",
    indicatorsPlan: "План",
    indicatorsDone: "Факт",
    indicatorsFail: "Не выполнено",
    eventsPlan: "План",
    eventsDone: "Факт",
    eventsFail: "Не выполнено",
  },
];
const summaryProgramsMergeCells = [
  { s: { r: 0, c: 2 }, e: { r: 0, c: 18 } },
  { s: { r: 1, c: 2 }, e: { r: 1, c: 18 } },
  { s: { r: 2, c: 2 }, e: { r: 2, c: 18 } },
  { s: { r: 3, c: 2 }, e: { r: 3, c: 18 } },
  { s: { r: 4, c: 2 }, e: { r: 4, c: 18 } },
  { s: { r: 5, c: 2 }, e: { r: 5, c: 18 } },
  { s: { r: 6, c: 2 }, e: { r: 6, c: 18 } },
  { s: { r: 8, c: 0 }, e: { r: 9, c: 0 } },
  { s: { r: 8, c: 1 }, e: { r: 9, c: 1 } },
  { s: { r: 8, c: 2 }, e: { r: 9, c: 2 } },
  { s: { r: 8, c: 3 }, e: { r: 9, c: 3 } },
  { s: { r: 8, c: 4 }, e: { r: 9, c: 4 } },
  { s: { r: 8, c: 5 }, e: { r: 9, c: 5 } },
  { s: { r: 8, c: 6 }, e: { r: 8, c: 7 } },
  { s: { r: 8, c: 8 }, e: { r: 8, c: 10 } },
  { s: { r: 8, c: 11 }, e: { r: 8, c: 13 } },
  { s: { r: 8, c: 14 }, e: { r: 8, c: 16 } },
];

const summaryVicePremiersHeadings = [
  {
    vicePremier: " ",
    vicePremierPhoto: " ",
    govPrograms: " ",
    performance: " ",
    populationSatisfaction: " ",
    cashPlan: " ",
    cashFact: " ",
    goalsPlan: " ",
    goalsDone: " ",
    goalsFail: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    indicatorsFail: " ",
    eventsPlan: " ",
    eventsDone: " ",
    eventsFail: " ",
  },
  {
    vicePremier: " ",
    vicePremierPhoto: " ",
    govPrograms: " ",
    performance: " ",
    populationSatisfaction: " ",
    cashPlan: " ",
    cashFact: " ",
    goalsPlan: " ",
    goalsDone: " ",
    goalsFail: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    indicatorsFail: " ",
    eventsPlan: " ",
    eventsDone: " ",
    eventsFail: " ",
  },
  {
    vicePremier: " ",
    vicePremierPhoto: " ",
    govPrograms: " ",
    performance: " ",
    populationSatisfaction: " ",
    cashPlan: " ",
    cashFact: " ",
    goalsPlan: " ",
    goalsDone: " ",
    goalsFail: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    indicatorsFail: " ",
    eventsPlan: " ",
    eventsDone: " ",
    eventsFail: " ",
  },
  {
    vicePremier: " ",
    vicePremierPhoto: " ",
    govPrograms: " ",
    performance: " ",
    populationSatisfaction: " ",
    cashPlan: " ",
    cashFact: " ",
    goalsPlan: " ",
    goalsDone: " ",
    goalsFail: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    indicatorsFail: " ",
    eventsPlan: " ",
    eventsDone: " ",
    eventsFail: " ",
  },
  {
    vicePremier: " ",
    vicePremierPhoto: " ",
    govPrograms: " ",
    performance: " ",
    populationSatisfaction: " ",
    cashPlan: " ",
    cashFact: " ",
    goalsPlan: " ",
    goalsDone: " ",
    goalsFail: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    indicatorsFail: " ",
    eventsPlan: " ",
    eventsDone: " ",
    eventsFail: " ",
  },
  {
    vicePremier: " ",
    vicePremierPhoto: " ",
    govPrograms: " ",
    performance: " ",
    populationSatisfaction: " ",
    cashPlan: " ",
    cashFact: " ",
    goalsPlan: " ",
    goalsDone: " ",
    goalsFail: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    indicatorsFail: " ",
    eventsPlan: " ",
    eventsDone: " ",
    eventsFail: " ",
  },
  {
    vicePremier: " ",
    vicePremierPhoto: " ",
    govPrograms: " ",
    performance: " ",
    populationSatisfaction: " ",
    cashPlan: " ",
    cashFact: " ",
    goalsPlan: " ",
    goalsDone: " ",
    goalsFail: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    indicatorsFail: " ",
    eventsPlan: " ",
    eventsDone: " ",
    eventsFail: " ",
  },
  {
    vicePremier: " ",
    vicePremierPhoto: " ",
    govPrograms: " ",
    performance: " ",
    populationSatisfaction: " ",
    cashPlan: " ",
    cashFact: " ",
    goalsPlan: " ",
    goalsDone: " ",
    goalsFail: " ",
    indicatorsPlan: " ",
    indicatorsDone: " ",
    indicatorsFail: " ",
    eventsPlan: " ",
    eventsDone: " ",
    eventsFail: " ",
  },
  {
    vicePremier: "Вице-премьер",
    vicePremierPhoto: "Фото",
    govPrograms: "Кол-во госпрограмм",
    performance: "Уровень достижения",
    populationSatisfaction: "Удовлетворенность населения",
    cashPlan: "Кассовое исполнение",
    cashFact: " ",
    goalsPlan: "Цели",
    goalsDone: " ",
    goalsFail: " ",
    indicatorsPlan: "Показатели",
    indicatorsDone: " ",
    indicatorsFail: " ",
    eventsPlan: "Мероприятия",
    eventsDone: " ",
    eventsFail: " ",
  },
  {
    vicePremier: " ",
    vicePremierPhoto: " ",
    govPrograms: " ",
    performance: " ",
    populationSatisfaction: " ",
    cashPlan: "План",
    cashFact: "Факт",
    goalsPlan: "План",
    goalsDone: "Факт",
    goalsFail: "Не выполнено",
    indicatorsPlan: "План",
    indicatorsDone: "Факт",
    indicatorsFail: "Не выполнено",
    eventsPlan: "План",
    eventsDone: "Факт",
    eventsFail: "Не выполнено",
  },
];
const summaryVicePremiersMergeCells = [
  { s: { r: 0, c: 2 }, e: { r: 0, c: 18 } },
  { s: { r: 1, c: 2 }, e: { r: 1, c: 18 } },
  { s: { r: 2, c: 2 }, e: { r: 2, c: 18 } },
  { s: { r: 3, c: 2 }, e: { r: 3, c: 18 } },
  { s: { r: 4, c: 2 }, e: { r: 4, c: 18 } },
  { s: { r: 5, c: 2 }, e: { r: 5, c: 18 } },
  { s: { r: 6, c: 2 }, e: { r: 6, c: 18 } },
  { s: { r: 8, c: 0 }, e: { r: 9, c: 0 } },
  { s: { r: 8, c: 1 }, e: { r: 9, c: 1 } },
  { s: { r: 8, c: 2 }, e: { r: 9, c: 2 } },
  { s: { r: 8, c: 3 }, e: { r: 9, c: 3 } },
  { s: { r: 8, c: 4 }, e: { r: 9, c: 4 } },
  { s: { r: 8, c: 5 }, e: { r: 8, c: 6 } },
  { s: { r: 8, c: 7 }, e: { r: 8, c: 9 } },
  { s: { r: 8, c: 10 }, e: { r: 8, c: 12 } },
  { s: { r: 8, c: 13 }, e: { r: 8, c: 15 } },
];

type TTablesParamsOptions = {
  [key: string]: {
    headings: Record<string, string | object>[];
    mergeCells?: Range[] | undefined;
    wscols: Record<string, number>[];
  };
};

const tablesParams = (data: any): TTablesParamsOptions => ({
  govPrograms: {
    headings: govProgramsHeadings,
    mergeCells: govProgramsMergeCells,
    wscols: getGovProgramsWscols(data, govProgramsHeadings),
  },
  vicePremiers: {
    headings: vicePremiersHeadings,
    mergeCells: vicePremiersMergeCells,
    wscols: getVicePremiersWscols(data, vicePremiersHeadings),
  },
  programEvents: {
    headings: programEventsHeadings,
    mergeCells: programEventsMergeCells,
    wscols: getProgramEventsWscols(data, programEventsHeadings),
  },
  summaryPrograms: {
    headings: summaryProgramsHeadings,
    mergeCells: summaryProgramsMergeCells,
    wscols: getSummaryProgramsWscols(data, summaryProgramsHeadings),
  },
  summaryVicePremiers: {
    headings: summaryVicePremiersHeadings,
    mergeCells: summaryVicePremiersMergeCells,
    wscols: getSummaryVicePremiersWscols(data, summaryVicePremiersHeadings),
  },
});

const useTableExportParams = (
  data:
    | IGovProgramDataItemWithoutIcon[]
    | IVicePremiersDataItem[]
    | IEventItem[]
    | IGeneralInfoDataItemWithoutIcon[]
    | IGeneralInfoPremiersDataItemWithoutId[]
    | undefined,
  tableName: string
) => {
  const { headings, mergeCells, wscols } = tablesParams(data)[tableName];
  return { headings, mergeCells, wscols };
};

export default useTableExportParams;

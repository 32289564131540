import { Box, Button, TextField } from "@mui/material";
import { FormEventHandler, useCallback, useState } from "react";

import { useAppDispatch } from "../../redux/hooks";
import { actions } from "../../redux/slices/auth/tokenSlice";
import { css } from "./index.styled";

export const Login = () => {
  const dispatch = useAppDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleUsernameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUsername(event.target.value);
    },
    []
  );

  const handlePasswordChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
    },
    []
  );

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(() => {
    dispatch(actions.getToken({ username, password }));
  }, [dispatch, password, username]);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Box sx={css.control}>
        <TextField
          label="Логин"
          name="username"
          onChange={handleUsernameChange}
          required
        />
      </Box>
      <Box sx={css.control}>
        <TextField
          label="Пароль"
          name="password"
          type="password"
          onChange={handlePasswordChange}
          required
        />
      </Box>
      <Button sx={css.submit} variant="contained" type="submit">
        Авторизоваться
      </Button>
    </Box>
  );
};

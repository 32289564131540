import {
  Box,
  Button,
  CircularProgress,
  Grid,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DoubleDownArrow, DoubleUpArrow } from "ASSETS/svg/arrow/index";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Checkpoints } from "SRC/components/Checkpoints/Checkpoints";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { Overlay } from "SRC/pages/App.styled";
import { EventCardSidebar } from "SRC/pages/Program/common/EventCard/EventCardSidebar/EventCardSidebar";
import { useCashExecution } from "SRC/redux/slices/gosprogram/hooks/useCashExecution";
import { useControlPoints } from "SRC/redux/slices/gosprogram/hooks/useControlPoints";
import { useEventData } from "SRC/redux/slices/gosprogram/hooks/useEventData";
import { useModalSideBar } from "SRC/redux/slices/gosprogram/hooks/useModalSideBar";
import themeConfig from "SRC/theme";

import { css } from "./EventCard.styled";
import { EventTabs } from "./EventTabs/EventTabs";

interface IWidgetDate {
  nameDate: string;
  date: string;
  colorDate?: string;
}

interface IHeaderProps {
  setOpen?: Dispatch<SetStateAction<boolean>>;
  open?: boolean;
  setEventModalMode?: Dispatch<
    SetStateAction<"default" | "eventCard" | "eventsFilter">
  >;
  eventModalMode?: "default" | "eventCard" | "eventsFilter";
  data?: Record<string, any>[];
  header?: any;
}

interface IResultValue {
  id: number;
  name: string;
  unitName: string;
  total: number;
  done: number;
  percentage: number;
}

interface IDeadlineValue {
  name: string;
  plan: string;
  fact: string;
}

type TSidebarData = [IResultValue[], IDeadlineValue];

const WidgetDate = ({ nameDate, date, colorDate }: IWidgetDate) => (
  <Box sx={css.widgetDateContainer}>
    <Typography component={"div"} color={"text.secondary"} sx={css.textDate}>
      {nameDate}
    </Typography>
    <Typography
      component={"div"}
      color={colorDate ? "success.main" : "text.onAccent"}
      fontWeight={"bold"}
      sx={css.textDate}
    >
      {date}
    </Typography>
  </Box>
);

const Header = ({
  setOpen,
  open,
  setEventModalMode,
  eventModalMode,
  data,
  header,
}: IHeaderProps) => {
  const theme = useTheme<typeof themeConfig>();
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const width1500 = useMediaQuery("(min-width: 1500px)");

  const [openTitle, setOpenTitle] = useState<boolean>(false);

  const maxLength = lg ? 500 : width1500 ? 300 : 250;

  const truncatedString =
    data && data.length > 0 ? data[0].NAME.substring(0, maxLength) + "..." : "";
  const isLargeString =
    data && data.length > 0 && data[0].NAME.length > maxLength;
  const displayText = openTitle
    ? data && data.length > 0
      ? data[0].NAME
      : ""
    : truncatedString;

  return (
    <>
      <Box sx={{ marginBottom: "1rem", marginTop: "2rem" }}>
        <Grid container>
          {data && data.length > 0 ? (
            <Box sx={css.titleBottom(openTitle, isLargeString) as SxProps}>
              {isLargeString ? (
                <>
                  {displayText}
                  {openTitle ? (
                    <DoubleUpArrow
                      style={css.expandIcon}
                      onClick={() => setOpenTitle(!openTitle)}
                    />
                  ) : (
                    <DoubleDownArrow
                      style={css.expandIcon}
                      onClick={() => setOpenTitle(!openTitle)}
                    />
                  )}
                </>
              ) : (
                data[0].NAME
              )}
            </Box>
          ) : (
            ""
          )}
        </Grid>
      </Box>
      <Box sx={css.event}>
        {header[0].headerIndicators.map((item: any, i: number) => {
          return (
            <Grid item container sx={css.headerEvent} key={i}>
              <Grid item>
                <Typography sx={css.topText as SxProps} className="grayText">
                  {item.indicator}
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={css.topText as SxProps} className="whiteText">
                  {item.value}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
        <Grid item container sx={css.headerEvent}>
          <Grid item sx={css.event} className="alignCenter smallGap">
            <Typography sx={css.topText as SxProps} className="grayText">
              Реализация в субъектах
            </Typography>
            <Typography sx={css.topText as SxProps} className="whiteText">
              {header[0].realization.total}
            </Typography>
          </Grid>
          <Box sx={css.event}>
            <Box sx={css.event} className="smallGap">
              <Typography sx={css.topText as SxProps} className="grayText">
                Выполнено{" "}
              </Typography>
              <Typography sx={css.topText as SxProps} className="greenText">
                {header[0].realization.done}
              </Typography>
            </Box>
            <Box sx={css.event} className="smallGap">
              <Typography sx={css.topText as SxProps} className="grayText">
                Не выполнено{" "}
              </Typography>
              <Typography sx={css.topText as SxProps} className="redText">
                {header[0].realization.notDone}
              </Typography>
            </Box>
            <Box sx={css.event} className="smallGap">
              <Typography sx={css.topText as SxProps} className="grayText">
                Не предоставлена отчетность{" "}
              </Typography>
              <Typography sx={css.topText as SxProps} className="grayText">
                {header[0].realization.noData}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Box>
      <Box sx={css.headerEventContainer}>
        {(open || eventModalMode === "default") && (
          <Box flex={"0"}>
            <Box
              sx={css.closeButton as SxProps}
              onClick={() => {
                setOpen?.(false);
                setEventModalMode?.("default");
              }}
            >
              Закрыть
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export const EventCard = ({
  setOpen,
  open,
  setEventModalMode,
  eventModalMode,
}: IHeaderProps) => {
  const { loaded } = useEventData();

  const { items: controlPoints } = useControlPoints();
  const { items: modalSideBar } = useModalSideBar();
  const { items: cashExecution } = useCashExecution();

  const { data: data1 } = cashExecution[0] || {};
  const { data: modalSideBarData1 } = modalSideBar[0] || {};

  const [headerData, setHeaderData] = useState<any>([]);
  const [checkpointsData, setCheckpointsData] = useState<any>([]);
  const [modalSideBarData, setModalSideBarData] = useState<any>([]);
  const [modalSideBarMetaData, setModalSideBarMetaData] = useState<any>([]);
  const [footerView, setFooterView] = useState<
    "controlPoints" | "reason" | "correction"
  >("controlPoints");

  const { data } = controlPoints[0] || {};

  useEffect(() => {
    if (data1 && data1.length > 0) {
      const { data: data2 } = data1[0] || {};
      setHeaderData(data2);
    }
    if (data && data.length > 0) {
      const { data: checkpoints } = data[0] || {};
      setCheckpointsData(checkpoints);
    }
    if (modalSideBarData1 && modalSideBarData1.length > 0) {
      const { data: sideBar } = modalSideBarData1[0] || {};
      setModalSideBarMetaData(sideBar[0]);
      const { data: sideBar1 } = sideBar[0] || {};
      setModalSideBarData(sideBar1);
    }
  }, [data1, data, modalSideBarData1, controlPoints]);

  const header = useMemo<any>(
    () => [
      {
        code: "code",
        name: "name",
        headerIndicators: [
          {
            indicator: "Ответственный",
            value: "Министерство культуры Российской Федерации",
          },
          {
            indicator: "Федеральный проект",
            value: "Культурная среда",
          },
          {
            indicator: "Уровень мероприятия",
            value: "Национальный проект",
          },
          {
            indicator: "Единица измерения",
            value: "Единицы",
          },
        ],
        realization: {
          total: 55,
          done: 48,
          notDone: 5,
          noData: 2,
        },
      },
    ],
    []
  );

  const sidebar = useMemo<TSidebarData>(
    () => [
      [
        {
          id: 1,
          name: "Значение результата",
          unitName: modalSideBarMetaData["Ед. измерения результата"],
          total:
            modalSideBarData?.find((it: any) => it.code === "1352")?.sum || 0,
          done:
            modalSideBarData?.find((it: any) => it.code === "1358")?.text || 0,
          percentage: roundNumbersToFixed(
            modalSideBarData?.find((it: any) => it.code === "1370")?.sum || 0,
            0
          ),
        },
        {
          id: 2,
          name: "Финансирование",
          unitName: modalSideBarMetaData["Ед. измерения бюджета"],
          total: roundNumbersToFixed(
            modalSideBarData?.find((it: any) => it.code === "1360")?.sum || 0,
            0
          ),
          done: roundNumbersToFixed(
            modalSideBarData?.find((it: any) => it.code === "1361")?.sum || 0,
            0
          ),
          percentage: roundNumbersToFixed(
            modalSideBarData?.find((it: any) => it.code === "1362")?.sum || 0,
            0
          ),
        },
        {
          id: 3,
          name: "Реализация в субъектах",
          unitName: modalSideBarMetaData["Ед. измерения результата"],
          total:
            modalSideBarData?.find((it: any) => it.code === "1363")?.sum || 0,
          done:
            modalSideBarData?.find((it: any) => it.code === "1364")?.sum || 0,
          percentage: roundNumbersToFixed(
            modalSideBarData?.find((it: any) => it.code === "1365")?.sum || 0,
            0
          ),
        },
      ],
      {
        name: "Сроки реализации",
        plan:
          modalSideBarData?.find((it: any) => it.code === "1366")?.sum || "-",
        fact:
          modalSideBarData?.find((it: any) => it.code === "1367")?.text || "-",
      },
    ],
    [modalSideBarData]
  );
  if (!loaded) {
    return (
      <Box sx={{ width: "90vw", height: "70vh" }}>
        <Overlay>
          <CircularProgress />
        </Overlay>
      </Box>
    );
  }

  return (
    <Box sx={css.eventWrapper}>
      <Grid sx={css.headerWrapper as SxProps}>
        <Header
          setOpen={setOpen}
          open={open}
          setEventModalMode={setEventModalMode}
          eventModalMode={eventModalMode}
          data={headerData}
          header={header}
        />
      </Grid>
      <Grid container sx={css.contentEvent as SxProps}>
        <Grid item xs={3} sx={css.sideBarEvent as SxProps}>
          <EventCardSidebar />
        </Grid>
        <Grid item xs={9} sx={css.tabsEvent}>
          <EventTabs />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <Box
            sx={css.eventWidgetContainer as SxProps}
            className="eventWidgetBottom"
          >
            <Button
              sx={css.sideBarButton(footerView === "controlPoints")}
              onClick={() => setFooterView("controlPoints")}
            >
              Контрольные точки
            </Button>
            <Button
              sx={css.sideBarButton(footerView === "reason")}
              onClick={() => setFooterView("reason")}
            >
              Причина отклонения
            </Button>
            <Button
              sx={css.sideBarButton(footerView === "correction")}
              onClick={() => setFooterView("correction")}
            >
              Корректировка значения/срока
            </Button>
          </Box>
        </Grid>
        <Grid item xs={9}>
          {checkpointsData && checkpointsData.length > 0 && (
            <Box sx={css.checkpointContainer}>
              <Checkpoints checkpoints={checkpointsData} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

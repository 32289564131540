import { Box, Tabs, useMediaQuery, useTheme } from "@mui/material";
import React, { CSSProperties, ReactNode, SyntheticEvent } from "react";
import { TableActionsButtons } from "SRC/components/TableActionsButtons/TableActionsButtons";
import {
  IGovProgramDataItemWithoutIcon,
  IVicePremiersDataItem,
} from "SRC/pages/Main/common/ComplexGrid/util/interfaces";
import { IEventItem } from "SRC/pages/Program/common/EventsSection/util/interfaces";
import {
  IGeneralInfoDataItemWithoutIcon,
  IGeneralInfoPremiersDataItemWithoutId,
} from "SRC/pages/Summary/common/SummaryComplexGrid/util/interfaces";
import { Range } from "xlsx";

import {
  NumContainer,
  RightNodeContainer,
  TabsContainer,
  TabsTitlesContainer,
  TabsWithBgrContainer,
  TabTitle,
  TabWithNumContainer,
} from "./CustomTabs.styled";

export interface ITab {
  value: string | number;
  label: string | number;
  numberOfItems?: number;
  borderColor?: string;
  disabled?: boolean;
  sx?: any;
}

interface IProps {
  isSmall?: boolean;
  contained?: boolean;
  widthFull?: boolean;
  value: string | number;
  onChange: (event: SyntheticEvent<Element, Event>, value: any) => void;
  ariaLabel?: string;
  style?: any;
  stylePanel?: any;
  tabs: ITab[];
  components: any;
  withBgr?: boolean;
  rightNode?: any;
  isModal?: boolean;
  numberOfItems?: null | number;
  borderType?: string;
  additional?: JSX.Element;
  styleContainer?: CSSProperties;
  displayRightNode?: boolean;
  displayTabs?: boolean;
  rightSideFilters?: ReactNode;
  withTableButtons?: boolean;
  isTableModalOpen?: boolean;
  setTableModalOpen?: (value: boolean) => void;
  downloadData?:
    | IGovProgramDataItemWithoutIcon[]
    | IVicePremiersDataItem[]
    | IEventItem[]
    | IGeneralInfoDataItemWithoutIcon[]
    | IGeneralInfoPremiersDataItemWithoutId[];
  fileName?: string;
  wscols?: Record<string, number>[];
  headings?: Record<string, string | object>[];
  mergeCells?: Range[] | undefined;
  buttonsStyle?: CSSProperties;
  infoRows?: Record<string, string>[][];
}

interface ITabPanelProps {
  value?: string | number;
  index?: string | number;
  children?: any;
  isSmall?: boolean;
  sx?: any;
}

interface IRenderTabsProps {
  children?: React.ReactNode;
  isSmall?: boolean;
  borderType?: string;
}

const TabPanel = (props: ITabPanelProps) => {
  const { children, value, index, isSmall, sx, ...other } = props;

  return (
    <Box
      style={{
        flex: 1,
        minHeight: 0,
        paddingTop: !isSmall ? "2rem" : "1rem",
      }}
      sx={sx}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
};

export const CustomTabs = (props: IProps) => {
  const {
    value,
    onChange,
    ariaLabel,
    style,
    stylePanel,
    tabs,
    components,
    withBgr,
    rightNode,
    isSmall,
    contained,
    widthFull,
    additional = null,
    borderType,
    styleContainer,
    displayRightNode,
    displayTabs = true,
    rightSideFilters,
    withTableButtons,
    isTableModalOpen,
    setTableModalOpen,
    downloadData,
    fileName,
    headings,
    mergeCells,
    wscols,
    buttonsStyle,
    infoRows,
  } = props;

  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("lg"));

  const RenderTabs = (props: IRenderTabsProps) => {
    const { children, borderType } = props;
    return match && withBgr ? (
      <TabsTitlesContainer withBgr>
        <TabsWithBgrContainer>
          {children}
          {additional}
        </TabsWithBgrContainer>
        {rightNode && <RightNodeContainer>{rightNode}</RightNodeContainer>}
      </TabsTitlesContainer>
    ) : (
      <TabsTitlesContainer
        borderType={borderType}
        filtersNode={Boolean(rightSideFilters)}
      >
        {children}
        {displayRightNode && rightNode}
      </TabsTitlesContainer>
    );
  };

  return (
    <TabsContainer
      isSmall={isSmall}
      widthFull={widthFull}
      styles={styleContainer}
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
        height: isSmall || contained ? "100%" : "auto",
      }}
    >
      {displayTabs && (
        <RenderTabs borderType={borderType}>
          <Box style={{ display: "flex", width: "100%", gap: "2rem" }}>
            <Tabs
              value={value}
              onChange={onChange}
              aria-label={ariaLabel}
              sx={style}
            >
              {tabs?.map((item: ITab, i: number) => (
                <TabTitle
                  key={item.value}
                  label={
                    <TabWithNumContainer>
                      {item.label}
                      {item.numberOfItems === 0 ? (
                        <NumContainer borderColor={item.borderColor}>
                          0
                        </NumContainer>
                      ) : item.numberOfItems ? (
                        <NumContainer borderColor={item.borderColor}>
                          {item.numberOfItems}
                        </NumContainer>
                      ) : null}
                    </TabWithNumContainer>
                  }
                  value={item.value}
                  disabled={item.disabled}
                  sx={item.sx}
                  id={`tab-${i}`}
                />
              ))}
            </Tabs>
            {!match && rightSideFilters}
          </Box>
          {withTableButtons &&
            !match &&
            downloadData &&
            fileName &&
            wscols &&
            headings && (
              <TableActionsButtons
                open={isTableModalOpen}
                setOpen={setTableModalOpen}
                downloadData={downloadData}
                fileName={fileName}
                wscols={wscols}
                headings={headings}
                mergeCells={mergeCells}
                buttonsStyle={buttonsStyle}
                infoRows={infoRows}
              />
            )}
        </RenderTabs>
      )}
      {components?.map((item: any, i: number) => {
        return (
          <TabPanel
            key={i}
            value={value}
            index={tabs[i].value}
            isSmall={isSmall}
            sx={stylePanel}
          >
            {item}
          </TabPanel>
        );
      })}
    </TabsContainer>
  );
};

import { Box, Grid, SxProps, Typography, useTheme } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { WarningTriangle } from "ASSETS/svg";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";

import { css } from "../EventCard.styled";

export const EventCardSidebar = () => {
  const theme = useTheme();

  return (
    <Box sx={css.sideBarTop}>
      <Box sx={css.borderContainer}>
        <Typography sx={css.sideBarTopHeaderText}>
          Уровень достижения мероприятия
        </Typography>
        <Box sx={css.achievementLevel}>
          <Typography sx={css.achievementLevelText} className="big">
            99
          </Typography>
          <Typography sx={css.achievementLevelText} className="small">
            ,28
          </Typography>
          <Typography sx={css.achievementLevelText} className="percent">
            &nbsp;%
          </Typography>
        </Box>
        <ProgressBar
          value={90}
          progressColor={theme.palette.primary.contrastText}
        />
      </Box>
      <Box sx={css.borderContainer}>
        <Typography
          sx={css.sideBarTopHeaderText}
          style={{ marginBottom: "1rem" }}
        >
          Количество корректировок
        </Typography>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography sx={css.sideBarGrayText}>
              ухудшилось значение / срок
            </Typography>
          </Grid>
          <Grid item sx={css.flexRow}>
            <Typography sx={css.sideBarValueText} className="red">
              2
            </Typography>
            &nbsp;/&nbsp;
            <Typography sx={css.sideBarValueText} className="red">
              2
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography sx={css.sideBarGrayText}>
              улучшилось значение / срок
            </Typography>
          </Grid>
          <Grid item sx={css.flexRow}>
            <Typography sx={css.sideBarValueText} className="green">
              2
            </Typography>
            &nbsp;/&nbsp;
            <Typography sx={css.sideBarValueText} className="green">
              1
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={css.borderContainer}>
        <Grid container sx={css.sideBarTopItemContainer}>
          <Grid item sx={css.sideBarTopHeaderText}>
            Значение мероприятия
          </Grid>
          <Grid item>
            <WarningTriangle
              style={{
                width: "1.5rem",
                height: "1.5rem",
              }}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography sx={css.sideBarGrayText}>
              план / факт / прогноз
            </Typography>
          </Grid>
          <Grid item sx={css.flexRow}>
            <Typography sx={css.sideBarValueText} className="white">
              80
            </Typography>
            &nbsp;/&nbsp;
            <Typography sx={css.sideBarValueText} className="white">
              -
            </Typography>
            &nbsp;/&nbsp;
            <Typography sx={css.sideBarValueText} className="white">
              80
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={css.borderContainer}>
        <Grid container sx={css.sideBarTopItemContainer}>
          <Grid item sx={css.sideBarTopHeaderText}>
            Срок реализации
          </Grid>
          <Grid item>
            <WarningTriangle
              style={{
                width: "1.5rem",
                height: "1.5rem",
              }}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography sx={css.sideBarGrayText}>план / факт</Typography>
          </Grid>
          <Grid item sx={css.flexRow}>
            <Typography sx={css.sideBarValueText} className="white">
              30.03.2022
            </Typography>
            &nbsp;/&nbsp;
            <Typography sx={css.sideBarValueText} className="white">
              -
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container sx={css.sideBarTopItemContainer}>
          <Grid item sx={css.sideBarTopHeaderText}>
            Финансирование
          </Grid>
          <Grid item>
            <Box>
              <Select
                // value={age}
                // onChange={handleChange}
                defaultValue={10}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={10}>млдр &#8381;</MenuItem>
                <MenuItem value={20}>млн &#8381;</MenuItem>
                <MenuItem value={30}>тыс &#8381;</MenuItem>
              </Select>
            </Box>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography sx={css.sideBarGrayText}>план / факт</Typography>
          </Grid>
          <Grid item sx={css.flexRow}>
            <Typography sx={css.sideBarValueText} className="white">
              110,00
            </Typography>
            &nbsp;/&nbsp;
            <Typography sx={css.sideBarValueText} className="white">
              55,00
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

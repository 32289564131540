import moment from "moment";
import { IProgram } from "SRC/redux/slices/main/slices/programs";

export const getInfoRowsGovProgram = (
  program: string,
  programs: IProgram[],
  govProgramsTableFilter: string
) => {
  return [
    [
      {
        t: "s",
        v: "Наименование отчета:",
      },
      {
        t: "s",
        v: "Госпрограмма:",
      },
      {
        t: "s",
        v: "Тип госпрограммы:",
      },
      {
        t: "s",
        v: "Содержимое выгрузки:",
      },
      {
        t: "s",
        v: "Данные отчета по состоянию на:",
      },
      {
        t: "s",
        v: "Дата выгрузки отчета:",
      },
    ],
    [
      {
        t: "s",
        v: "Список Государственных программ",
      },
      {
        t: "s",
        v: `${
          program
            ? programs.filter((el: IProgram) => el.gp_code === program)[0]
                ?.gp_name
            : "Все"
        }`,
      },
      {
        t: "s",
        v: `${govProgramsTableFilter ? govProgramsTableFilter : "Все"}`,
      },
      {
        t: "s",
        v: "Консолидированные данные в разрезе государственных программ по показателям государственных программ, мероприятиям структурных элементов, уровню достижения государственных программ",
      },
      {
        t: "s",
        v: ` `,
      },
      {
        t: "s",
        v: `${moment().format("DD.MM.YYYY, HH:mm")}`,
      },
    ],
  ];
};

export const getInfoRowsVicePremiers = (govProgramsTableFilter: string) => {
  return [
    [
      {
        t: "s",
        v: "Наименование отчета:",
      },
      {
        t: "s",
        v: "Тип госпрограммы:",
      },
      {
        t: "s",
        v: "Содержимое выгрузки:",
      },
      {
        t: "s",
        v: "Данные отчета по состоянию на:",
      },
      {
        t: "s",
        v: "Дата выгрузки отчета:",
      },
    ],
    [
      {
        t: "s",
        v: "Список вице-премьеров",
      },
      {
        t: "s",
        v: `${govProgramsTableFilter ? govProgramsTableFilter : "Все"}`,
      },
      {
        t: "s",
        v: "Консолидированные данные в разрезе вице-премьеров по показателям Государственных программ, мероприятиям структурных элементов, уровню достижения государственных программ",
      },
      {
        t: "s",
        v: ` `,
      },
      {
        t: "s",
        v: `${moment().format("DD.MM.YYYY, HH:mm")}`,
      },
    ],
  ];
};

export const getInfoRowsEvents = (
  program: string,
  programType: string,
  structureElement?: string
) => {
  return [
    [
      {
        t: "s",
        v: "Наименование отчета:",
      },
      {
        t: "s",
        v: "Госпрограмма:",
      },
      {
        t: "s",
        v: "Тип госпрограммы:",
      },
      {
        t: "s",
        v: "Структурный элемент:",
      },
      {
        t: "s",
        v: "Содержимое выгрузки:",
      },
      {
        t: "s",
        v: "Данные отчета по состоянию на:",
      },
      {
        t: "s",
        v: "Дата выгрузки отчета:",
      },
    ],
    [
      {
        t: "s",
        v: "Мероприятия структурных элементов Государственной программы",
      },
      {
        t: "s",
        v: program,
      },
      {
        t: "s",
        v: programType,
      },
      {
        t: "s",
        v: structureElement ? structureElement : "Все",
      },
      {
        t: "s",
        v: "Перечень мероприятий (результатов) структурных элементов Государственных программ, включающий основные параметры выполнения мероприятия (плановое значение, фактическое значение, выполнение)",
      },
      {
        t: "s",
        v: ` `,
      },
      {
        t: "s",
        v: `${moment().format("DD.MM.YYYY, HH:mm")}`,
      },
    ],
  ];
};

export const getInfoRowsSummaryPrograms = (program: string) => {
  return [
    [
      {
        t: "s",
        v: "Наименование отчета:",
      },
      {
        t: "s",
        v: "Госпрограмма:",
      },
      {
        t: "s",
        v: "Содержимое выгрузки:",
      },
      {
        t: "s",
        v: "Данные отчета по состоянию на:",
      },
      {
        t: "s",
        v: "Дата выгрузки отчета:",
      },
    ],
    [
      {
        t: "s",
        v: "Сводная таблица по Государственным программам",
      },
      {
        t: "s",
        v: program,
      },
      {
        t: "s",
        v: "Сводная таблица сведений о реализации Государственных программ в разрезе Государственных программ",
      },
      {
        t: "s",
        v: ` `,
      },
      {
        t: "s",
        v: `${moment().format("DD.MM.YYYY, HH:mm")}`,
      },
    ],
  ];
};

export const getInfoRowsSummaryVicePremiers = () => {
  return [
    [
      {
        t: "s",
        v: "Наименование отчета:",
      },
      {
        t: "s",
        v: "Содержимое выгрузки:",
      },
      {
        t: "s",
        v: "Данные отчета по состоянию на:",
      },
      {
        t: "s",
        v: "Дата выгрузки отчета:",
      },
    ],
    [
      {
        t: "s",
        v: "Сводная таблица по вице-премьерам",
      },
      {
        t: "s",
        v: "Сводная таблица сведений о реализации Государственных программ в разрезе вице-премьеров",
      },
      {
        t: "s",
        v: ` `,
      },
      {
        t: "s",
        v: `${moment().format("DD.MM.YYYY, HH:mm")}`,
      },
    ],
  ];
};

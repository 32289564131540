import { Box } from "@mui/material";
import React, { CSSProperties, FC } from "react";

import { css } from "./index.styled";

interface IProps {
  value: number;
  progressColor?: string;
  style?: CSSProperties;
}

export const ProgressBar: FC<IProps> = ({
  value = 0,
  progressColor,
  style,
}) => {
  const val = value > 100 ? 100 : value;

  return (
    <Box sx={{ ...css.uiProgressBarStyle(val, progressColor), ...style }}>
      <Box sx={css.progressLine(val, progressColor)} />
    </Box>
  );
};
